import React, { useEffect } from 'react'
import { connectLink } from '../Components/DynamicConnectLink';
import { navBarBrand, dropDownArrow } from '../Components/Images'
// import { DownloadApp } from '../Components/DownloadApp';
import { Login } from '../Components/Login';
import { useGetBrandData } from '../Components/useGetBrandData';

export const Header = ({ path, brandName }) => {
    // changing the styles based on brand
    var bodyStyles = document.body.style
    if (brandName && brandName === '/reframefinancial') {
        bodyStyles.fontFamily = "Open Sans"
        bodyStyles.backgroundColor = 'var(--reframeWhite)'
    } else {
        bodyStyles.fontFamily = "Manrope"
        bodyStyles.backgroundColor = 'var(--cream)'
    }

    // auto close navbar while clicking outside the document
    const navbar = document.querySelector('.navbar');
    const navbarToggler = document.querySelector('.navbar-toggler');
    document.addEventListener('click', (event) => {
        if (navbarToggler) {
            if (navbarToggler.getAttribute('aria-expanded') === 'true' && !navbar.contains(event.target) && !navbarToggler.contains(event.target)) {
                navbarToggler.click();
            }
        }
    });

    const [, logo] = useGetBrandData({ brandName });
    const jqueryCode = () => {
        // $('[data-bs-toggle=dropdown]').on('mouseover', function (e) {
        //     console.log($(this))
        //     $(this).addClass('show')
        //     $(this).attr('aria-expanded', true)
        //     $(this).next('ul.dropdown-menu').addClass('show')
        //     $(this).prop('data-bs-popper', 'static')

        // })

        // $('[data-bs-toggle=dropdown]').on('mouseleave', function (e) {
        //     console.log($(this))
        //     $(this).removeClass('show')
        //     $(this).attr('aria-expanded', false)
        //     $(this).next('ul.dropdown-menu').removeClass('show')
        //     $(this).prop('data-bs-popper', 'static')

        // })
        // console.log(window.innerWidth)
        if (window.innerWidth > 991) {
            document.querySelectorAll('.navbar .nav-item.dropdown').forEach(function (everyitem) {

                let timer;
                everyitem.addEventListener('mouseover', function (e) {
                    let el_link = this.querySelector('a[data-bs-toggle]');
                    if (el_link != null) {
                        clearTimeout(timer);
                        let nextEl = el_link.nextElementSibling;
                        el_link.classList.add('show');
                        nextEl.classList.add('show');
                        el_link.setAttribute('aria-expanded', 'true')
                        const dataBsPopper = document.createAttribute('data-bs-popper')
                        dataBsPopper.value = 'static'
                        nextEl.setAttributeNode(dataBsPopper)
                    }

                });
                everyitem.addEventListener('mouseleave', function (e) {
                    let el_link = this.querySelector('a[data-bs-toggle]');
                    if (el_link != null) {
                        let nextEl = el_link.nextElementSibling;
                        timer = setTimeout(() => {
                            el_link.classList.remove('show');
                            nextEl.classList.remove('show');
                            el_link.setAttribute('aria-expanded', 'false')
                            nextEl.removeAttribute('data-bs-popper')
                        }, 2000)
                    }

                })
            });
        }

    }

    useEffect(() => {
        window.addEventListener('resize', jqueryCode)
        jqueryCode()
    }, [])

    return (
        <React.Fragment>
            <nav className={`navbar navbar-expand-lg my_navbar sticky-top ${brandName && brandName === '/reframefinancial' ? 'reframe' : ''}`}>
                <div className="container">
                    {
                        brandName && brandName === '/reframefinancial' ? '' :
                            <a className="navbar-brand" href={brandName === '' ? '/' : brandName}>
                                <img src={navBarBrand} alt="navBarBrand" />
                            </a>
                    }
                    {
                        brandName && logo &&
                        <a className="navbar-brand coBrand" href={brandName}>
                            <img src={require(`../Assests/Images/${logo}.png`)} alt="navBarBrand" className='coBrandLogo' />
                        </a>
                    }
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav-scroll navbar-nav ms-auto align-items-center" style={{ '--bs-scroll-height': '100vh' }}>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href={`${path}`}>Caregiver Screener</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={connectLink}>Find Services</a>
                            </li>
                            <li className="nav-item dropdown beforeSignUp">
                                <a className="nav-link dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Additional Resources

                                    <span className="dropdown_arrow">
                                        <img src={dropDownArrow} alt="dropDownArrow" loading='lazy' />

                                    </span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href={`${brandName}/resources`}>Resources to Support You</a></li>
                                    <li><a className="dropdown-item" href={`${brandName}/caregiverInsights`}>Caregiver Insights</a></li>
                                    {
                                        brandName !== "/buddyins" ? <li><a className="dropdown-item" href={`${brandName}/contact`}>Connect to a Specialist</a></li> : ""
                                    }
                                    {/* <li><a className="dropdown-item" href="/#">Something else here</a></li> */}
                                </ul>
                            </li>
                            {/* <DownloadApp /> */}
                            <Login />
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

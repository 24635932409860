import React from 'react'
import { hero_bg } from '../Components/Images'
import { useGetBrandData } from '../Components/useGetBrandData';

export const HeroSection = ({ brandName }) => {
    const [desc] = useGetBrandData({ brandName });
    return (
        <React.Fragment>
            <section id="hero_section">
                <div className="wrapper ">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="hero_div">
                                    <div className='hero_left'>
                                        <p className="hero_para_1">family caregiver support program</p>
                                        <p className="hero_para_2">Better care starts with self care</p>
                                    </div>
                                    <div className="hero_right">
                                        <img src={hero_bg} alt="hero_bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="hero_description">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {
                                desc && desc !== "" ?

                                    <div className="description_para coBrand">

                                        <p className='coBrandDesc'>{desc}</p>
                                    </div>
                                    :
                                    <div className="description_para">
                                        <p>Wherever you are in your caregiving journey, TCARE is here to provide you and your family
                                            with
                                            the tools, resources, and support so you can focus on what matters most - caring for your
                                            loved
                                            ones and yourself.</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { useGetBrandData } from '../Components/useGetBrandData'
import { ContactSection } from '../Layouts/ContactSection'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { Header } from '../Layouts/Header'

export const Contact = () => {
  const [formSubmitted, setformSubmitted] = useState(false);
  const { dynamicPath } = useParams()

  var [brandName] = useBrandName();
  brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


  const [path] = useDynamicPath({
    dynamicPath
  })


  const [, , , mail] = useGetBrandData({ brandName });

  const {
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    const myJson = {
      "message": {
        "subject": `Contact Form : ${brandName.substring(1).toUpperCase()}`,
        "body": {
          "contentType": "Html",
          "content": `
            <b style='margin-right:10px;'>Name:</b>${data.firstName}<br>
            <b style='margin-right:10px;'>Name:</b>${data.lastName}<br>
            <b style='margin-right:10px;'>Email:</b><a href="mailto:${data.email}">${data.email}</a><br>
            <b style='margin-right:10px;'>Mobile:</b><a href="tel:${data.mobile}">${data.mobile}</a><br>
            <b style='margin-right:10px;'>Role:</b>${data.role}<br>
            <b style='margin-right:10px;'>Additional Info:</b>${data.message}<br>
          `
        },
        "toRecipients": [
          {
            "emailAddress": {
              "address": mail
            }
          }
        ]
      },
      "saveToSentItems": "true"
    }
    axios.post('https://dfd-api.tcare.ai', { myJson }, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log(response)
        response.status === 200 ? setformSubmitted(true) : setformSubmitted(false)
      }).catch((err) => alert(err))
  }

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [brandName])
  return (
    loading ? '' :
      <React.Fragment>
        <Header path={path} brandName={brandName} />
        <ContactSection brandName={brandName} formSubmitted={formSubmitted} handleSubmit={handleSubmit} register={register} onSubmit={onSubmit} />
        <GoMobile brandName={brandName} />
        <Footer brandName={brandName} />
      </React.Fragment>
  )
}

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { butterfly_small } from '../Components/Images'

export const CareBurdenMainSection = ({ brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="combined_article_main_section" className={brand}>
                    {
                        brand === 'reframefinancial' ?
                            <section id='article_hero_description' className={brand}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="description_para">
                                                <p>
                                                    Caregivers will experience different stressors & burdens during their caregiving journey. These depend on stress level, what a caregiver is doing on a routine basis, and the relationship they have...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''
                    }
                    <section id="article_main_section" className={brand}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="articleMain_outer">
                                        <div className="articleMain_header">
                                            <div className="articleMain_left">
                                                {/* <img
                                        src={careInsightMain_large}
                                        alt="careInsightMain_large"
                                    /> */}
                                                <div className="tcare_team">
                                                    <img
                                                        src={butterfly_small}
                                                        alt="butterfly_small"
                                                        width={60}
                                                    />
                                                    <p>TCARE TEAM</p>
                                                </div>
                                            </div>

                                            <div className="articleMain_right">
                                                <h2>October 14, 2022</h2>
                                                <p className="para_1">Caregiving</p>
                                            </div>
                                        </div>
                                        <div className="articleMain_content">
                                            <p>
                                                Family caregivers will experience different stressors & burdens during their caregiving journey. These depend on stress level, what a caregiver is doing on a routine basis, and the relationship they have with their loved one. It is normal and expected that a caregiver will experience at least one, if not all, of these burdens during their caregiver journey.
                                            </p>

                                            <h2 className='sub_heading'>
                                                Stress Burden
                                            </h2>

                                            <p>
                                                This is the overall stress a caregiver feels when taking care of their loved one. In a caregiving situation is very normal to feel stress. This stress may arise in situations like cooking a meal and mom decides she is no longer hungry, when dad forgets to take the medication laid out for him, or when a husband tries to drive to the store even after he is no longer able to drive. For a caregiver who feels the stress of responsibility for the care receiver’s well-being, these are all stressful situations.
                                            </p>

                                            <h2 className='sub_heading'>
                                                Objective Burden
                                            </h2>

                                            <p>
                                                This burden is felt when one’s caregiving duties take priority over other life events. Many times, a caregiver must miss out on soccer games, social events, or even time at work to care for their loved one. This can even lead to an inability to fully be present at events they do attend because your loved one’s needs are always top of mind.
                                            </p>

                                            <h2 className='sub_heading'>
                                                Relationship Burden
                                            </h2>

                                            <p>
                                                This is how the relationship between caregiver and care receiver changes as they become their primary caregiver. They are no longer a daughter/wife/husband/son, but instead the person responsible for their personal hygiene, medication schedule, and their loved one’s overall well-being.
                                            </p>

                                            <p>
                                                Every caregiver journey is different, and these burdens are experienced in different ways. These are the three ways that we measure the different types of burden that a family caregiver experiences throughout caregiving.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </React.Fragment>
    )
}

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { burdens_large } from '../Components/Images'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { ArticleHeroSection } from '../Layouts/ArticleHeroSection'
import { CareBurdenMainSection } from '../Layouts/CareBurdenMainSection'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { Header } from '../Layouts/Header'
import { MoreInsightsSection } from '../Layouts/MoreInsightsSection'

export const CaregiverBurdens = () => {
    const { dynamicPath } = useParams()

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


    const [path] = useDynamicPath({
        dynamicPath
    })

    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <Header path={path} brandName={brandName} />
                <ArticleHeroSection
                    para_1='family caregiver support program'
                    para_2={<>Caregiver <br /> Burdens</>}
                    desc='Caregivers will experience different stressors & burdens during their caregiving journey. These depend on stress level, what a caregiver is doing on a routine basis, and the relationship they have...'
                    imgUrl={burdens_large}
                    brandName={brandName}
                />
                <CareBurdenMainSection brandName={brandName} />
                <MoreInsightsSection moreHeader={true} brandName={brandName} />
                <GoMobile brandName={brandName} />
                <Footer brandName={brandName} />
            </React.Fragment>
    )
}

import React from 'react'
import { useParams } from 'react-router'
import { AppStore, footerLogo, PlayStore } from '../Components/Images'
import { useSetBrand } from '../Components/useSetBrand'

export const ApplicationDownload = () => {
    var { dynamicPath } = useParams()
    useSetBrand({ dynamicPath })
    // useEffect(() => {
    //     const brandName = localStorage.getItem('brand_name')
    //     const currentUrl = window.location.href
    //     console.log(currentUrl)
    //     console.log(window)
    //     if (currentUrl.includes(brandName)) {

    //     } else {
    //         window.location.replace(`${brandName}/app`)
    //     }
    // }, [])
    return (
        <React.Fragment>
            <section id='app_download_section'>
                <nav>
                    <img src={footerLogo} alt="" />
                </nav>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="app_container">
                                <a href="https://play.google.com/store/apps/details?id=com.tcare.engage" className='play_store'>
                                    <img src={PlayStore} alt="PlayStore" />
                                    <div className='right'>
                                        <p className='para_1'>Get It On</p>
                                        <p className='para_2'>Google Play</p>
                                    </div>
                                </a>
                                <a href="https://apps.apple.com/in/app/tcare/id1643563077" className='app_store'>
                                    <img src={AppStore} alt="AppStore" />
                                    <div className='right'>
                                        <p className='para_1'>Download on the</p>
                                        <p className='para_2'>App Store</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

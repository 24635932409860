import React from 'react'
import { useGetBrandData } from '../../Components/useGetBrandData';

export const GTLRecCashMainSection = ({ brandName }) => {
    const [, , resources] = useGetBrandData({ brandName });
    return (
        <React.Fragment>
            {
                resources &&
                <section id="gtl_rec_cash_main_section">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="cash_link_wrapper">
                                    <a href={resources.brochure} className="cash_link">Recover Cash Product Brochure</a>
                                    <a href={resources.infographic} className="cash_link">Recover Cash Product Infographic</a>
                                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/786682764?h=6db62cbaf0" width="640" height="360" frameBorder={0} allowFullScreen></iframe>
                                    {
                                        resources && resources.gtlAdditionalContent && resources.gtlAdditionalContent
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </React.Fragment>
    )
}

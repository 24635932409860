import React from 'react'
// import { useBrandName } from '../Components/useBrandName';
// import { useGetBrandData } from '../Components/useGetBrandData'

export const Attachment = ({ attachment }) => {
    // var [brandName] = useBrandName();
    // const [, , resources] = useGetBrandData({ brandName });
    return (
        <React.Fragment>
            {
                // resources && resources.attachment !== "" ?
                //     <div className='my_iframe'>
                //         <iframe className='' src={require(`../Attachments/${resources.attachment}.pdf`)} width='100%' height='100%' title='my_iframe'></iframe>
                //     </div>
                //     : <h1 className='display-1 text-secondary d-flex justify-content-center'>Not Found</h1>
                attachment && attachment !== "" ?
                    <div className='my_iframe'>
                        <iframe className='' src={require(`../Attachments/${attachment}.pdf`)} width='100%' height='100%' title='my_iframe'></iframe>
                    </div>
                    : <h1 className='display-1 text-secondary d-flex justify-content-center'>Not Found</h1>
            }
        </React.Fragment>
    )
}

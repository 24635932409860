import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { downArrow } from '../Components/Images'
export const CareCountSection = ({ brandName }) => {

    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])



    useEffect(() => {
        var myCollapse = document.querySelectorAll('.care_count_anchor')
        // console.log(myCollapse);
        myCollapse.forEach(item => {
            item.addEventListener('mouseenter', (e) => {
                // console.log(item);
                // console.log(e);
                // item.setAttribute('aria-expanded', 'true')
                //    e.target.ariaExpanded=true
                //    item.nextElementSibling.classList.add('show');
                e.target.click()
            })

            item.addEventListener('mouseleave', (e) => {
                // item.setAttribute('aria-expanded', 'true')
                //    e.target.ariaExpanded=false
                return null
            })

        })
    }, [loading])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="care_count_section" className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                {
                                    brandName && brandName === '/gtl' ?
                                        <p className="care_count_para">Other Examples of Caregiving:</p>
                                        :
                                        <p className="care_count_para">What Counts as Caregiving?</p>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                {
                                    brandName && (brandName === '/gtl' || brandName === '/reframefinancial') ?
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_1" aria-expanded="false" aria-controls="care_ul_1">
                                            Physical/Health
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                        :
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_1" aria-expanded="false" aria-controls="care_ul_1">
                                            Physical/Health Support
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                }

                                <ul className="care_ul collapse" id="care_ul_1">
                                    <li>Transportation to and from appointments</li>
                                    <li>Managing medication adherence and refills</li>
                                    <li>Monitoring health condition and coordinating medical care with providers</li>
                                    <li>Bathing, dressing and personal care</li>
                                    <li>Errands, grocery shopping and meal preparation</li>
                                    <li>House cleaning and laundry</li>
                                    <li>Home exercise and mobility</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                {
                                    brandName && (brandName === '/gtl' || brandName === '/reframefinancial') ?
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_2" aria-expanded="false" aria-controls="care_ul_2">
                                            Emotional/Social
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                        :
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_2" aria-expanded="false" aria-controls="care_ul_2">
                                            Emotional/Social Support
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                }

                                <ul className="care_ul collapse" id="care_ul_2">
                                    <li>Providing emotional support</li>
                                    <li>Coordinating with other family members</li>
                                    <li>Monitoring day-to-day mood changes and needs</li>
                                    <li>Reading or playing games</li>
                                    <li>Coordinating activities outside the home</li>
                                    <li>Advocating and educating on condition and needs</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                {
                                    brandName && (brandName === '/gtl' || brandName === '/reframefinancial') ?
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_3" aria-expanded="false" aria-controls="care_ul_3">
                                            Financial
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                        :
                                        <a href="/#" className="care_count_anchor" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#care_ul_3" aria-expanded="false" aria-controls="care_ul_3">
                                            Financial Support
                                            <img src={downArrow} alt="downArrow" />
                                        </a>
                                }

                                <ul className="care_ul collapse" id="care_ul_3">
                                    <li>Paying bills</li>
                                    <li>Managing finances</li>
                                    <li>Meeting with advisors</li>
                                    <li>Planning for future needs</li>
                                    <li>Coordinating home/auto maintenance</li>
                                    <li>Managing insurance</li>
                                    <li>Dealing with unexpected events/expenses</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
    )
}

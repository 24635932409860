import React from 'react'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { Header } from '../Layouts/Header'
import { HeroSection } from '../Layouts/HeroSection'
import { StartedSection } from '../Layouts/StartedSection'

import useDynamicPath from '../Components/useDynamicPath';
import { useParams } from 'react-router-dom';
import { useSetBrand } from '../Components/useSetBrand'
import { useBrandName } from '../Components/useBrandName'
import { GTLHeroSection } from '../Layouts/GTL/GTLHeroSection'
import { GTLStartedSection } from '../Layouts/GTL/GTLStartedSection'
import { useState } from 'react'
import { useEffect } from 'react'
import { ReframeHeroSection } from '../Layouts/ReframeFinancial/ReframeHeroSection'
import { ReframeStartedSection } from '../Layouts/ReframeFinancial/ReframeStartedSection'

export const Home = () => {
    var { dynamicPath } = useParams()

    useSetBrand({ dynamicPath })

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';

    const [path] = useDynamicPath({
        dynamicPath
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [brandName])

    return (
        <React.Fragment>
            {
                loading ? '' :
                    brandName && brandName === '/gtl' ?
                        <>
                            <Header path={path} brandName={brandName} />
                            <GTLHeroSection brandName={brandName} />
                            <GTLStartedSection path={path} brandName={brandName} />
                            <GoMobile brandName={brandName} />
                            <Footer brandName={brandName} />
                        </>
                        :
                        brandName && brandName === '/reframefinancial' ?
                            <>
                                <Header path={path} brandName={brandName} />
                                <ReframeHeroSection brandName={brandName} />
                                <ReframeStartedSection path={path} brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
                            :
                            <>
                                <Header path={path} brandName={brandName} />
                                <HeroSection brandName={brandName} />
                                <StartedSection path={path} brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
            }
        </React.Fragment>
    )
}

import React from 'react'
import { resourceImg } from '../../Components/Images'
import { useLoaded } from '../../Components/useLoaded'

export const GTLResHeroSection = () => {
    const [loaded] = useLoaded()
    return (
        <React.Fragment>
            <section id="resource_hero_section" className='gtl'>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="res_hero_main">
                                    <p className="res_para_1">family caregiver support program</p>
                                    <p className="res_para_2">Resources to Support You</p>
                                    <img src={resourceImg} alt="resourceImg" className={'res_hero_img my_animation ' + loaded} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

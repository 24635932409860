import React from 'react'
import { connectLink } from '../../Components/DynamicConnectLink'
import { question, care, housing, education } from '../../Components/Images'

export const GTLStartedSection = ({ path, brandName }) => {
    return (
        <React.Fragment>
            <section id="started_section">
                <div className="container started_container">
                    <div className="row started_row_1">
                        <div className="col text-center">
                            <h1 className="started_heading">Getting Started:</h1>
                        </div>
                    </div>

                    <div className="row started_row_2">
                        <div className="col-md-6">
                            <center>
                                <div className="main_card_1 gtl">
                                    <div className="my_card_1 gtl">
                                        <p>Am I Considered a Caregiver?</p>
                                        <span className="gtl">
                                            <img src={question} alt="question" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${brandName}/whoIsACareGiver`} className="started_link">Learn More</a>
                                </div>
                            </center>
                        </div>
                        <div className="col-md-6">
                            <center>
                                <div className="main_card_1 gtl">
                                    <div className="my_card_1 gtl">
                                        <p>Identify <br />Your Caregiver Burnout Risk</p>
                                        <span className="gtl">
                                            <img src={care} alt="care" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${path}`} className="started_link">Take Screener</a>
                                </div>
                            </center>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <center>
                                <div className="main_card_1 gtl">
                                    <div className="my_card_1 gtl">
                                        <p>Connect <br /> with Services & Resources to Support Your Needs</p>
                                        <span className="gtl">
                                            <img src={housing} alt="" width="33" />
                                        </span>
                                    </div>
                                    <a href={connectLink}
                                        className="started_link">Get Connected</a>
                                </div>
                            </center>
                        </div>
                        <div className="col-md-6">
                            <center>
                                <div className="main_card_1 gtl">
                                    <div className="my_card_1 gtl">
                                        <p>Caregiver Education & Benefit Information</p>
                                        <span className="gtl">
                                            <img src={education} alt="" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${brandName}/resources`} className="started_link">Resources for Me </a>
                                </div>
                            </center>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

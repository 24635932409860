import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { coupleDancing, familyGroupPhoto, familyWheelChair, ReframeCareGiverFamily1, ReframeCareGiverFamily2, ReframeCareGiverFamily3 } from '../Components/Images'
export const CareCardSection = ({ path, brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id='care_card_combined_section' className={brand}>

                    {/* this is only for reframe financial */}
                    {
                        brand === 'reframefinancial' ?
                            <section id="care_description" className={brand}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="description_para">
                                                <p>A caregiver is anyone who is providing regular care to someone who needs assistance taking
                                                    care of themselves.</p>
                                                <a href={`${path}`}>
                                                    Take Caregiver Screener
                                                </a>
                                                {/* <div className='reframeSideBanner'>
                                                    <img src={ReframeSideBanner} alt="ReframeSideBanner" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''
                    }
                    <section id="care_card_section" className={brand}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="care_card_outer mx-auto">
                                        <div className="row care_card_row">
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_1 mx-auto">
                                                    <p>Caring for an elderly relative</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_2 mx-auto">
                                                    <p>Parent of a child with special needs</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_3 mx-auto">
                                                    <p>Caring for a spouse</p>
                                                    {
                                                        brand === 'reframefinancial' ?
                                                            <img src={ReframeCareGiverFamily1} alt="ReframeCareGiverFamily1" />
                                                            : <img src={coupleDancing} alt="coupleDancing" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row care_card_row">
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_4 mx-auto">
                                                    <p>Taking care of <br /> a relative with dementia</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_5 mx-auto">
                                                    <p>Caring for a sick child</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_6 mx-auto">
                                                    <p>Supporting a family member or friend through cancer treatment</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_7 mx-auto">
                                                    <p>A foster family</p>
                                                    {
                                                        brand === 'reframefinancial' ?
                                                            <img src={ReframeCareGiverFamily2} alt="ReframeCareGiverFamily2" />
                                                            : <img src={familyWheelChair} alt="familyWheelChair" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_8 mx-auto">
                                                    <p>Supporting a family member struggling with mental illness</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 mx-auto care_card_col">
                                                <div className="care_card card_9 mx-auto">
                                                    <p>Caring for a military veteran</p>
                                                    {
                                                        brand === 'reframefinancial' ?
                                                            <img src={ReframeCareGiverFamily3} alt="ReframeCareGiverFamily3" />
                                                            : <img src={familyGroupPhoto} alt="familyGroupPhoto" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </React.Fragment>
    )
}

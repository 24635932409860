import React from 'react'
import { ReframeHeroFamily } from '../../Components/Images'
import { useGetBrandData } from '../../Components/useGetBrandData';
import { useLoaded } from '../../Components/useLoaded';

export const ReframeHeroSection = ({ brandName }) => {
    const [loaded] = useLoaded() // for image animation
    const [desc] = useGetBrandData({ brandName });
    return (
        <React.Fragment>
            <section id="reframe_hero_section">
                <div className="wrapper ">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="hero_div">
                                    <div className='hero_left'>
                                        <p className="hero_para_1">family caregiver support program</p>
                                        <p className="hero_para_2">Better care starts with self care</p>
                                    </div>
                                    <div className="hero_right">
                                        {/* <img src={ReframeBanner} alt="ReframeBanner" /> */}
                                        <img className={'reframeHeroFamilyImg my_animation ' + loaded} src={ReframeHeroFamily} alt="ReframeHeroFamily" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="hero_description" className='reframe'>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {
                                desc && desc !== "" ?

                                    <div className="description_para coBrand">
                                        <p className='coBrandDesc'>{desc}</p>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

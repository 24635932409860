import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { howCaregiving_large } from '../Components/Images'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { ArticleHeroSection } from '../Layouts/ArticleHeroSection'
import { CareIdentityMainSection } from '../Layouts/CareIdentityMainSection'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { Header } from '../Layouts/Header'
import { MoreInsightsSection } from '../Layouts/MoreInsightsSection'

export const CaregivingChangesIdentity = () => {
    const { dynamicPath } = useParams()

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


    const [path] = useDynamicPath({
        dynamicPath
    })

    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <Header path={path} brandName={brandName} />
                <ArticleHeroSection
                    para_1='family caregiver support program'
                    para_2='How Caregiving Changes Your Identity'
                    desc='There is no such thing as an “average” caregiver. Specific families, cultures, and expectations concerning duties they are supposed to perform — even the amount of pressure they feel to take on...
                '
                    imgUrl={howCaregiving_large}
                    brandName={brandName}
                />
                <CareIdentityMainSection brandName={brandName} />
                <MoreInsightsSection moreHeader={true} brandName={brandName} />
                <GoMobile brandName={brandName} />
                <Footer brandName={brandName} />
            </React.Fragment>
    )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.css";
import './Assests/Styles/index.css';
import './Assests/Styles/responsive.css';
import './Assests/Styles/mobile_home.css';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
// import { BrowserRouter } from 'react-router-dom';


import { MsalProvider } from "@azure/msal-react";
import { msalClientApp } from "./api/auth";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalClientApp}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

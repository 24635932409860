import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { CareCardSection } from '../Layouts/CareCardSection'
import { CareCountSection } from '../Layouts/CareCountSection'
import { CareHeroSection } from '../Layouts/CareHeroSection'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { GTLCareCardSection } from '../Layouts/GTL/GTLCareCardSection'
import { GTLCareHeroSection } from '../Layouts/GTL/GTLCareHeroSection'
import { Header } from '../Layouts/Header'

export const WhoIsACareGiver = () => {
    const { dynamicPath } = useParams()

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


    const [path] = useDynamicPath({
        dynamicPath
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [brandName])
    return (
        <React.Fragment>
            {
                loading ? '' :
                    brandName && brandName === '/gtl' ?
                        <>
                            <Header path={path} brandName={brandName} />
                            <GTLCareHeroSection path={path} />
                            <GTLCareCardSection />
                            <CareCountSection brandName={brandName} />
                            <GoMobile brandName={brandName} />
                            <Footer brandName={brandName} />
                        </>
                        :
                        brandName && brandName === '/reframefinancial' ?
                            <>
                                <Header path={path} brandName={brandName} />
                                <CareHeroSection path={path} brandName={brandName} />
                                <CareCardSection path={path} brandName={brandName} />
                                <CareCountSection brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
                            :
                            <>
                                <Header path={path} brandName={brandName} />
                                <CareHeroSection path={path} brandName={brandName} />
                                <CareCardSection path={path} brandName={brandName} />
                                <CareCountSection brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
            }
        </React.Fragment>
    )
}

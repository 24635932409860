import {PublicClientApplication} from "@azure/msal-browser";
import {getMSALConfig, loginRequest} from "../msal.config";
import getBindings from "../config";
import {ProfileAPI} from "./profiles/profiles";

export const BRANDED_HOME_PATH = 'branded_home_path'
export const BRAND_NAME = 'brand_name'
export const SIGN_IN_LOCATION = 'sign_in_location'
export const PROFILES_PAGE = 'profile'
export const TEAMS_PAGE = 'team'

const msalConfig = getMSALConfig()

const bindings = getBindings()

export const msalClientApp = new PublicClientApplication(msalConfig);

// called after sign up and sign in
msalClientApp.handleRedirectPromise()
    .then(response => {
        // check if there is a response
        if (response && response.account) {
            // check if the response came from a user signing up
            if (response.account.homeAccountId && response.authority === bindings.b2cPolicies.authorities.signUp.authority) {
                // todo: do I need to setActiveAccount twice here? probably not
                msalClientApp.setActiveAccount(response.account)
                // get user account by home account id so user does not have to sign in after creating an account!
                const account = msalClientApp.getAccountByHomeId(response.account.homeAccountId)
                const accessTokenRequest = {
                    scopes: bindings.b2cPolicies.scopes,
                    authority: bindings.b2cPolicies.authorities.signUp.authority, // call sign up policy
                    redirectUri:  bindings.accountUrls.signUpRedirect, // redirect to create profile page  // todo: branded redirect
                    knownAuthorities: [bindings.b2cPolicies.authorityDomain],
                    account: account, // use account from above
                }
                // get token without requiring user interaction
                msalClientApp.acquireTokenSilent(accessTokenRequest).then((token) => {
                    msalClientApp.setActiveAccount(token.idTokenClaims)
                }).catch((e) => {
                    console.error(e)
                })
            } else {
                // when user logs in, check if they exist in profile database,
                // if they don't, set msal account and re-direct to 'create profile' workflow
                // if user logged in, just set the account to the account returned from login response
                const userId = response.account.idTokenClaims.oid
                if (response.account.idTokenClaims && response.account.idTokenClaims.tfp && response.account.idTokenClaims.tfp === bindings.b2cPolicies.names.signIn) {
                    // set respective company branding when user logs in
                    ProfileAPI.getProfileById(userId).then((profile) => {
                        if (profile && profile.companyId) {
                            ProfileAPI.getCompanyById(profile.companyId).then((company) => {
                                const brandedHomePath =  company.brandedHomePath
                                localStorage.setItem(BRANDED_HOME_PATH, brandedHomePath)
                            })
                        }
                    // if user signs in and has not created their profile yet,
                    // re-direct to create profile workflow
                    }).catch((e) => {
                        window.location.href = bindings.accountUrls.signUpRedirect
                    })
                }
                msalClientApp.setActiveAccount(response.account)
            }
        }
    })
    .catch((e) => {
        // redirect to home if user clicks cancel during sign in / sign up
        if (e.errorCode === 'access_denied') {
            const brandedHomePage = getBrandedUrl()

            window.location.href = brandedHomePage
        }
        console.error(e)
    })

export const getAccount = async () => {
    const activeAccount = msalClientApp.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalClientApp.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        return null
    }
    let request = {}
    // get user token after sign up
    if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.acr && (activeAccount.idTokenClaims.acr.toLowerCase() === bindings.b2cPolicies.names.signUp.toLowerCase())) {
        request = {
            scopes: bindings.b2cPolicies.scopes,
            authority: bindings.b2cPolicies.authorities.signUp.authority,
            redirectUri:  bindings.accountUrls.signUpRedirect,
            knownAuthorities: [bindings.b2cPolicies.authorityDomain],
            account: activeAccount,
        }
    } else if (activeAccount) {
        request = {
            ...loginRequest,
            account: activeAccount,
        };
    } else {
        request = {
            ...loginRequest,
            account: accounts[0],
        };
    }

    const authResult = await msalClientApp.acquireTokenSilent(request);
    let acct = parseJwt(authResult.idToken)
    return acct
};

export const signUp = () => {
    try {
        msalClientApp.loginRedirect({
            scopes: bindings.b2cPolicies.scopes,
            authority: bindings.b2cPolicies.authorities.signUp.authority,
            redirectUri:  bindings.accountUrls.signUpRedirect,
            knownAuthorities: [bindings.b2cPolicies.authorityDomain],
        })
    } catch (err) {
        console.error(err);
    }
}

export const signInFromProfilePage = () => {
    localStorage.setItem(SIGN_IN_LOCATION, PROFILES_PAGE)
    try {
        msalClientApp.loginRedirect({
            scopes: bindings.b2cPolicies.scopes,
            authority: bindings.b2cPolicies.authorities.signIn.authority,
            redirectUri:  bindings.accountUrls.signInRedirect,
            knownAuthorities: [bindings.b2cPolicies.authorityDomain],
        })
    } catch (err) {
        console.error(err);
    }
}

export const signInFromTeamPage = () => {
    localStorage.setItem(SIGN_IN_LOCATION, TEAMS_PAGE)
    try {
        msalClientApp.loginRedirect({
            scopes: bindings.b2cPolicies.scopes,
            authority: bindings.b2cPolicies.authorities.signIn.authority,
            redirectUri:  bindings.accountUrls.signInRedirect,
            knownAuthorities: [bindings.b2cPolicies.authorityDomain],
        })
    } catch (err) {
        console.error(err);
    }
}

export const signIn = () => {
    localStorage.removeItem(SIGN_IN_LOCATION)

    try {
        msalClientApp.loginRedirect({
            scopes: bindings.b2cPolicies.scopes,
            authority: bindings.b2cPolicies.authorities.signIn.authority,
            redirectUri:  bindings.accountUrls.signInRedirect,
            knownAuthorities: [bindings.b2cPolicies.authorityDomain],
        })
    } catch (err) {
        console.error(err);
    }
}

export const signOut = () => {
    msalClientApp.logoutRedirect({
        authority: bindings.b2cPolicies.authorities.signIn.authority,
        postLogoutRedirectUri: getBrandedUrl(),
    });
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getBrandedUrl(path) {
    let redirectUrl = bindings.hostUrl
    const brandedHomePath = localStorage.getItem(BRANDED_HOME_PATH)
    const brandName = localStorage.getItem(BRAND_NAME)
    if (brandedHomePath !== null && brandedHomePath !== undefined && brandedHomePath !== '') {
        redirectUrl += `/${brandedHomePath}`
    } else if (brandName !== null && brandName !== undefined && brandName !== '') {
        redirectUrl += `/${brandName}`
    }
    if (path !== null && path !== undefined) {
        redirectUrl += '/' + path
    }
    return redirectUrl
}

export function getSignInUrl() {
    let signInUrl = getBrandedUrl()
    const page = localStorage.getItem(SIGN_IN_LOCATION)
    if (page !== null && page !== undefined && page !== '') {
        signInUrl += '/' + page
    }
    return signInUrl
}
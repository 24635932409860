import React from 'react'
import { connectLink } from '../../Components/DynamicConnectLink'
import { ReframeHeart, ReframeHand, ReframeHome, ReframeEducation } from '../../Components/Images'

export const ReframeStartedSection = ({ path, brandName }) => {
    return (
        <React.Fragment>
            <section id="started_section" className='reframe'>
                <div className="container started_container">
                    <div className="row started_row_1">
                        <div className="col text-center">
                            <h1 className="started_heading">Getting Started:</h1>
                        </div>
                    </div>

                    <div className="row started_row_2">
                        <div className="col-sm-6">
                            <center>
                                <div className="main_card_1 reframe">
                                    <div className="my_card_1 reframe">
                                        <p>Am I Considered a Caregiver?</p>
                                        <span className='reframeHeart'>
                                            <img src={ReframeHeart} alt="ReframeHeart" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${brandName}/whoIsACareGiver`} className="started_link">Learn More</a>
                                </div>
                            </center>
                        </div>
                        <div className="col-sm-6">
                            <center>
                                <div className="main_card_1 reframe">
                                    <div className="my_card_1 reframe">
                                        <p>Help Identify my Caregiving Needs</p>
                                        <span className='reframeHand'>
                                            <img src={ReframeHand} alt="ReframeHand" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${path}`} className="started_link">Take Screener</a>
                                </div>
                            </center>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <center>
                                <div className="main_card_1 reframe">
                                    <div className="my_card_1 reframe">
                                        <p>Connect me to <br /> Available Resources</p>
                                        <span className='reframeHome'>
                                            <img src={ReframeHome} alt="ReframeHome" width="33" />
                                        </span>
                                    </div>
                                    <a href={connectLink}
                                        className="started_link">Find Services</a>
                                </div>
                            </center>
                        </div>
                        <div className="col-sm-6">
                            <center>
                                <div className="main_card_1 reframe">
                                    <div className="my_card_1 reframe">
                                        <p>Additional <br /> Caregiver Education</p>
                                        <span className='reframeEducation'>
                                            <img src={ReframeEducation} alt="ReframeEducation" width="33" />
                                        </span>
                                    </div>
                                    <a href={`${brandName}/resources`} className="started_link">Resources for Me </a>
                                </div>
                            </center>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

export const ArticleHeroSection = ({ para_1, para_2, desc, imgUrl, brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id='article_hero_section' className={brand}>
                    <div className='wrapper' style={{ backgroundImage: `url(${imgUrl})` }}>
                        <div className='overlay_bg'></div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="article_hero_main">

                                        <p className="article_para_1">{para_1}</p>
                                        <p className="article_para_2">{para_2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    brand === 'reframefinancial' ? '' :
                        <section id='article_hero_description' className={brand}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="description_para">
                                            <p>
                                                {desc}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </React.Fragment>
    )
}

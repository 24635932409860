import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { CareInsightsHeroSection } from '../Layouts/CareInsightsHeroSection'
import { CareInsightsMainSection } from '../Layouts/CareInsightsMainSection'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { GTLCareInsightsHeroSection } from '../Layouts/GTL/GTLCareInsightsHeroSection'
import { Header } from '../Layouts/Header'
import { MoreInsightsSection } from '../Layouts/MoreInsightsSection'

export const CaregiverInsights = () => {
    const { dynamicPath } = useParams()

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


    const [path] = useDynamicPath({
        dynamicPath
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                {
                    brandName && brandName === '/gtl' ?
                        <>
                            <Header path={path} brandName={brandName} />
                            <GTLCareInsightsHeroSection />
                            <CareInsightsMainSection />
                            <MoreInsightsSection brandName={brandName} />
                            <GoMobile brandName={brandName} />
                            <Footer brandName={brandName} />
                        </>
                        :
                        brandName && brandName === '/reframefinancial' ?
                            <>
                                <Header path={path} brandName={brandName} />
                                <CareInsightsHeroSection brandName={brandName} />
                                <CareInsightsMainSection brandName={brandName} />
                                <MoreInsightsSection brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
                            :
                            <>
                                <Header path={path} brandName={brandName} />
                                <CareInsightsHeroSection brandName={brandName} />
                                <CareInsightsMainSection brandName={brandName} />
                                <MoreInsightsSection brandName={brandName} />
                                <GoMobile brandName={brandName} />
                                <Footer brandName={brandName} />
                            </>
                }
            </React.Fragment>
    )
}

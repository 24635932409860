import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {ProfileAPI} from "../../api/profiles/profiles";
import {Header} from "../../Layouts/Header";
import {useBrandName} from "../../Components/useBrandName";
import useDynamicPath from "../../Components/useDynamicPath";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import {useForm} from "react-hook-form";
import Button from "react-bootstrap/Button";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useIsAuthenticated} from "@azure/msal-react";
import {getAccount, signInFromTeamPage} from "../../api/auth";
import {isValidEmail} from "../Profiles/inputValidation";
import getBindings from "../../config";

export const Team = () => {
    const isAuthenticated = useIsAuthenticated()
    const methods = useForm()
    const {register, handleSubmit} = methods;
    const navigate = useNavigate();

    const bindings = getBindings()
    // todo: change host to branded host based on who user signed up through
    const host = bindings.hostUrl

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';

    const {dynamicPath} = useParams()
    const [path] = useDynamicPath({
        dynamicPath
    })


    const [team, setTeam] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [readyToRender, setReadyToRender] = useState(false)
    useEffect(() => {
        getAccount().then((account) => {
            if (account) {
                let accountId = account.oid ? account.oid : account.sub
                ProfileAPI.getProfileById(accountId).then((profile) => {
                    if (profile && profile.teamId) {
                        setCurrentUser(profile)
                        ProfileAPI.getTeamById(profile.teamId).then((team) => {
                            setTeam(team)
                            setReadyToRender(true)
                        }).catch((e) => {
                            // todo: re-direct to branded
                            console.error(e)
                            window.location.href = `${host}`
                        })
                    } else {
                        // todo: re-direct to branded
                        window.location.href = `${host}`
                    }
                }).catch((e) => {
                    console.error(e)
                    // todo: re-direct to branded
                    window.location.href = `${host}`
                })
            } else {
                // if user tries to access this page without being authenticated,
                // re-direct user to login screen,
                // after successful login, re-direct them back to this page
                signInFromTeamPage()
            }
        }).catch((e) => {
            console.error(e)
            // todo: re-direct to branded
            window.location.href = `${host}`
        })
    }, [isAuthenticated, host])

    const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};

    function formatDate(oldDate) {
        const newDate = new Date(oldDate)
        return newDate.toLocaleDateString(undefined, dateOptions)
    }

    const [addMode, setAddMode] = useState(false)

    const onClickInviteButton = () => {
        setAddMode(!addMode)
    }

    const onSubmitInviteNewTeamMember = (data) => {
        if (!isValidEmail(data.email)) {
            setErrorMessage('Please enter a valid email.')
        } else {
            setErrorMessage('')
        }
        const body = {
            'email': data.email
        }
        ProfileAPI.inviteTeamMember(currentUser.teamId, body).then(() => {
            ProfileAPI.getTeamById(currentUser.teamId).then((updatedTeam) => {
                setErrorMessage('')
                setTeam(updatedTeam)
            }).catch((e) => {
                console.error(e) // todo: notify user
            })
        }).catch((e) => {
            // 409 error occurs when user is trying to invite another user that belongs to another team
            // at time of development, the decision was that users will be limited to one team
            if (e.response.status === 409) {
                setErrorMessage(`Users are limited to one care team. The user with the email '${data.email}' already belongs to a team. You are not allowed to add them to your team.`)
            } else {
                console.error(e) // todo: notify user
            }
        })
    }

    const onRemoveTeamMember = (teamMemberId) => {
        ProfileAPI.removeTeamMember(currentUser.teamId, teamMemberId).then(() => {
            ProfileAPI.getTeamById(currentUser.teamId).then((updatedTeam) => {
                setTeam(updatedTeam)
            }).catch((e) => {
                console.error(e) // todo: notify user
            })
        }).catch((e) => {
            console.error(e) // todo: notify user
        })
    }

    const onNavigateToProfile = () => {
        if (currentUser) {
            navigate(`/profile`)
        }
    }

    const onEnterEmailInvite = (event) => {
        if (event && event.keyCode === 13) {
            event.preventDefault()
            handleSubmit(onSubmitInviteNewTeamMember)()
        }
    }

    return (
        readyToRender &&
        <React.Fragment>
            <Header path={path} brandName={brandName}/>

            <div className={"App2"}>
                <div className={"formContainer1"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        // fontSize: "28px",
                        // color: "#611C73",
                        justifyContent: 'left'
                    }}>
                        <div className={"textAndIcon"}>
                            <IconButton onClick={() => onNavigateToProfile()}>
                                <ArrowBackIcon style={{
                                    color: "#391A52",
                                    marginLeft: ".25em",
                                    height: "17px",
                                    width: "17px",
                                    marginTop: ".25em",
                                    marginRight: '.25em'
                                }}></ArrowBackIcon>
                            <label htmlFor="code" style={{color: "#391A52", fontSize: "17px", fontWeight: '400'}}>Profile</label>
                            </IconButton>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "28px",
                        color: "#611C73",
                        marginBottom: "1em",
                        justifyContent: 'center'
                    }}>
                        <h2 style={{color: "#391A52", justifyContent: 'left'}}>Care Team</h2>
                    </div>
                    {
                        currentUser && currentUser.isBenefitRecipient && (
                        addMode ?
                            <div style={{marginLeft:'1em'}}>
                                <div style={{color:'red', display:'flex', flexDirection:'column', alignItems:'center'}}>
                                    <h5 hidden={!addMode || errorMessage === ''} >{errorMessage}</h5>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontSize: "28px",
                                    color: "#611C73",
                                    justifyContent: 'space-between'
                                }}>
                                    <label htmlFor="email" style={{color: "#FC4772", fontSize: "14px"}}>Invite via
                                        email</label>
                                    <Button style={{color: "#FC4772", fontSize: "14px"}} variant="contained"
                                            onClick={() => onClickInviteButton()}>Cancel</Button>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: '2em',
                                    justifyContent: 'space-between'
                                }}>
                                    <input style={{border: '1px solid #FC4772'}} onKeyDown={onEnterEmailInvite} placeholder="betty@gmail.com" {...register("email")} autoFocus={true}/>
                                    <IconButton onClick={handleSubmit(onSubmitInviteNewTeamMember)}>
                                        <AddCircleIcon style={{color: "#FC4772", marginBottom: '.25em'}}></AddCircleIcon>
                                    </IconButton>
                                </div>
                            </div>
                            :
                            <div style={{textAlign:'center'}}>
                                <Button style={{backgroundColor:'#FEF9F1', color:'#FC4772', borderRadius:'35px', border:'1px solid #FC4772', borderColor: '#FC4772', fontWeight:'400', marginBottom: '2em'}} onClick={() => onClickInviteButton()}>Invite New Member</Button>
                            </div>
                        )
                    }
                    <div style={{background: '#F8F0EC', borderRadius: '10px', marginBottom: '2em'}}>


                    {
                        team &&
                        team.map((teamMember, i) =>
                            <div style={{
                                marginBottom: '1em',
                                marginLeft: '1em',
                                marginRight: '1em',
                            }}>
                                <li style={{listStyleType: "none"}} key={teamMember.id}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: 'space-between',
                                        marginBottom: '-1em',
                                        marginTop: i !== 0 && '-1em'
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <AccountCircleIcon style={{
                                                color: "#FEF9F1",
                                                marginLeft: ".25em",
                                                height: "44px",
                                                width: "44px",
                                                marginTop: ".25em"
                                            }}></AccountCircleIcon>
                                            {
                                                teamMember.deleted ?
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center"
                                                        }}>
                                                            <label htmlFor="code" style={{
                                                                marginLeft: ".25em",
                                                                color: "#391A52",
                                                                fontSize: "20px",
                                                                fontWeight: "500",
                                                                marginBottom: "-2rem",
                                                                maxWidth: '180px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }} >{teamMember.firstName ? teamMember.firstName.concat(' ', teamMember.lastName) : teamMember.email}</label>
                                                            <HeartBrokenIcon style={{
                                                                color: "#FC4772",
                                                                marginLeft: ".25em",
                                                                height: "20px",
                                                                width: "20px",
                                                                marginBottom: "-3.25rem"
                                                            }}/>
                                                        </div>
                                                        <label htmlFor="code" style={{
                                                            marginLeft: ".25em",
                                                            color: "#391A52",
                                                            fontWeight: "300",
                                                            fontSize: "14px"
                                                        }}>Deleted</label>
                                                    </div>
                                                    :
                                                teamMember.registrationStatus === 'active' ?
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center"
                                                        }}>
                                                            <label htmlFor="code" style={{
                                                                marginLeft: ".25em",
                                                                color: "#391A52",
                                                                fontSize: "20px",
                                                                fontWeight: "500",
                                                                marginBottom: "-2rem"
                                                            }}>{teamMember.firstName.concat(' ', teamMember.lastName)}</label>
                                                            <FavoriteIcon style={{
                                                                color: "#FC4772",
                                                                marginLeft: ".25em",
                                                                height: "20px",
                                                                width: "20px",
                                                                marginBottom: "-3.25rem"
                                                            }}/>
                                                        </div>
                                                        <label htmlFor="code" style={{
                                                            marginLeft: ".25em",
                                                            color: "#391A52",
                                                            fontSize: "14px"
                                                        }}>{'Joined'.concat(' ', formatDate(teamMember.joinedDate))}</label>
                                                    </div>
                                                    :
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center"
                                                        }}>
                                                            <label htmlFor="code" style={{
                                                                marginLeft: ".25em",
                                                                color: "#391A52",
                                                                fontSize: "20px",
                                                                fontWeight: "500",
                                                                marginBottom: "-2rem",
                                                                maxWidth: '180px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }} >{teamMember.firstName ? teamMember.firstName.concat(' ', teamMember.lastName) : teamMember.email}</label>

                                                        </div>
                                                        <label htmlFor="code" style={{
                                                            marginLeft: ".25em",
                                                            color: "#391A52",
                                                            fontWeight: "300",
                                                            fontSize: "14px"
                                                        }}>Pending</label>
                                                    </div>
                                            }
                                        </div>
                                        {
                                            (currentUser && currentUser.isBenefitRecipient && !teamMember.deleted && teamMember.id !== currentUser.id) &&
                                            <IconButton onClick={() => onRemoveTeamMember(teamMember.id)}>
                                                <RemoveCircleIcon style={{
                                                    textAlign: "right",
                                                    color: "#FC4772",
                                                    float: "right",
                                                    // height: "44px",
                                                    // width: "44px",
                                                    fontSize: '24px',
                                                    marginTop: ".25em"
                                                }}></RemoveCircleIcon>
                                            </IconButton>
                                        }
                                    </div>
                                </li>
                                {i !== team.length - 1 && <hr />}
                            </div>
                            // </a>
                        )
                    }
                    </div>
                    <div style={{background:'#D3D3D3', borderRadius: '10px', fontSize:'14px', marginBottom: '2em'}}>
                        <p style={{margin:'.5em 1em'}}>
                            <span style={{textDecoration:'underline'}}>NOTE:</span> The <span style={{fontWeight:'bold'}}>“primary”</span> member of the care team is the employee or TCARE policy holder. Only the <span style={{fontWeight:'bold'}}>primary</span> member can add and remove members of the care team.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
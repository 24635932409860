import React, {useEffect, useState} from "react";
import {useForm, FormProvider, Controller} from "react-hook-form";

import {Header} from "../../Layouts/Header";
import {useBrandName} from "../../Components/useBrandName";
import useDynamicPath from "../../Components/useDynamicPath";
import {useNavigate, useParams} from "react-router-dom";

import {ProfileAPI} from "../../api/profiles/profiles";

// eslint-disable-next-line
import styles from './styles.css';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import {getAccount, getBrandedUrl, signInFromProfilePage} from "../../api/auth";
import {useIsAuthenticated} from "@azure/msal-react";
import {validateProfileUpdate} from "./inputValidation";
import getBindings from "../../config";
import ReactSelect from "react-select";
import {genderOptions} from "./data";
import Button from "react-bootstrap/Button";

export const ExistingProfile = () => {
    const isAuthenticated = useIsAuthenticated()

    const methods = useForm()
    const {register, handleSubmit, setValue, control} = methods;

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';

    const {dynamicPath} = useParams()
    const [path] = useDynamicPath({
        dynamicPath
    })

    const [isDetailsView, setIsDetailsView] = useState(false)
    const [profile, setProfile] = useState(null)
    const [readyToRender, setReadyToRender] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        getAccount().then((account) => {
            if (account && (account.sub || account.oid)) {
                setIsDetailsView(true)
                const id = account.sub ? account.sub : account.oid
                ProfileAPI.getProfileById(id, false).then((profile) => {
                    if (profile) {
                        // todo: figure out how to use react hook form to set as objecct not individual fields
                        setProfile(profile)
                        if (profile.address) {
                            setValue('addressLine1', profile.address.addressLine1)
                            setValue('addressLine2', profile.address.addressLine2)
                            setValue('city', profile.address.city)
                            setValue('state', profile.address.state)
                            setValue('zipCode', profile.address.zipCode)
                        }
                        setValue('firstName', profile.firstName)
                        setValue('lastName', profile.lastName)
                        setValue('phone', profile.phone)
                        setValue('birthDate', profile.birthDate)
                        setValue('gender', profile.gender ? profile.gender : 'none') // need this to default select input
                        setValue('employeeWorkEmail', profile.employeeWorkEmail)

                        setReadyToRender(true)
                    }
                }).catch((e) => {
                    // todo: re-direct to branded home page
                    console.error(e)
                    // if user tries to access their profile before they create one,
                    // re-direct them to the 'create profile' workflow
                    // todo: move bindings to const at top of component
                    window.location.href = `${getBindings().hostUrl}/post-signup`
                })
            } else {
                // if user tries to access this page without being authenticated,
                // re-direct user to login screen,
                // after successful login, re-direct them back to this page
                signInFromProfilePage()
            }
        }).catch((e) => {
            // todo: re-direct to branded home page
            console.error(e)
            window.location.href = `${getBindings().accountUrls.signUpRedirect}`
        })
    }, [isAuthenticated, setValue])

    const [errorMessage, setErrorMessage] = useState('')
    const onSubmit = (data) => {
        let address = {
            "addressLine1": data.addressLine1 === '' ? null : data.addressLine1,
            "addressLine2": data.addressLine2 === '' ? null : data.addressLine2,
            "city": data.city === '' ? null : data.city,
            "county": data.county === '' ? null : data.county,
            "state": data.state === '' || data.state === null ? null : data.state.toUpperCase(),
            "country": 'US', // default for now
            "zipCode": data.zipCode === '' ? null : data.zipCode,
        }
        let updatedProfile = {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "id": profile.id,
            "email": data.email,
            "gender": data.gender ? data.gender.value : null,
            "birthDate": data.birthDate === '' ? null : data.birthDate,
            "phone": data.phone === '' ? null : data.phone,
            "address": address,
        }

        let errorMessage = validateProfileUpdate(updatedProfile)
        if (errorMessage && errorMessage !== '') {
            setErrorMessage(errorMessage)
            return
        } else {
            setErrorMessage('')
        }

        ProfileAPI.updateProfile(profile.id, updatedProfile).then(() => {
            window.location.href = getBrandedUrl('profile')
        }).catch((e) => {
            // todo: display internal server error to user
            console.error(e)
        })
    };

    const onEditProfile = () => {
        setIsDetailsView(!isDetailsView)
    }

    const onEditTeam = () => {
        navigate(`/team`)
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: 42.45,
            minHeight: 42.45,
            opacity: isDetailsView && '0.4',
            borderColor: '#391A52',
            background: isDetailsView ? '#FEF9F1' : '#fff',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: !isDetailsView && '#391A52',
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: !isDetailsView && '#391A52',
            }
        }
    };

    return (
        readyToRender &&
        <React.Fragment>
            <Header path={path} brandName={brandName}/>

            {
                <div className="App2">
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div style={{display:'flex', flexDirection:'row', textAlign:'center', justifyContent:'space-evenly'}}>

                            {isDetailsView
                                ?
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => {
                                        onEditProfile()
                                    }}>
                                        <EditIcon style={{
                                            color: "#391A52",
                                            fontSize: '24px',
                                            marginBottom: '.25em'
                                        }}></EditIcon>
                                        <h2 style={{color: "#391A52", fontSize:"14px", fontWeight:'500'}}>Profile</h2>
                                    </IconButton>
                                :
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => {
                                        onEditProfile()
                                    }}>
                                        <CancelIcon style={{color: "#391A52", height: "24px", width: "24px"}}></CancelIcon>
                                        <h2 style={{color: "#391A52", fontSize:"14px", fontWeight:'500'}}>Cancel</h2>
                                    </IconButton>
                            }
                            {isDetailsView &&
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => {
                                        onEditTeam()
                                    }}>
                                        <GroupIcon style={{
                                            color: "#391A52",
                                            fontSize: '24px',
                                            marginBottom: '.25em'
                                        }}></GroupIcon>
                                    <h2 style={{color: "#391A52", fontSize: '14px'}}>Team</h2>
                                    </IconButton>
                            }
                            </div>
                            <div hidden={!(isDetailsView || profile.id)} style={{marginBottom:'2em'}}>

                                    <div style={{color:'red', display:'flex', flexDirection:'column', alignItems:'center'}}>
                                        <h5 hidden={isDetailsView || errorMessage === ''} >{errorMessage}</h5>
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input hidden={!profile.id} disabled={isDetailsView}
                                               placeholder="First Name" {...methods.register("firstName")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input hidden={!profile.id} disabled={isDetailsView}
                                               placeholder="Last Name" {...methods.register("lastName")} />
                                    </div>


                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div  className={'update'}  style={{flex: '1', width: '50%', color:'red'}} >
                                            <input style={{backgroundColor: isDetailsView ? '#FEF9F1' : '#fff', opacity: isDetailsView ? '0.4' : null}}  placeholder={profile.birthDate ? profile.birthDate : 'Birth Date'} type={"date"} disabled={isDetailsView}  {...methods.register("birthDate")} />
                                        </div>
                                        <div style={{flex: '1', width: '50%', marginLeft:'.5em'}} >
                                            <Controller
                                                name='gender'
                                                control={control}
                                                render={({ field }) => (
                                                    <ReactSelect
                                                        isDisabled={isDetailsView}
                                                        placeholder={profile.gender ? profile.gender : 'Gender'}
                                                        {...field}
                                                        options={genderOptions}
                                                        styles={customStyles}
                                                    ></ReactSelect>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView}
                                               placeholder="Phone Number" {...methods.register("phone")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView}
                                               placeholder="Street Address" {...register("addressLine1")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView}
                                               placeholder="Apt, suite, etc. (optional)" {...register("addressLine2")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView} placeholder="City" {...register("city")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView} placeholder="State" {...register("state")} />
                                    </div>
                                    <div className={isDetailsView ? "formInput2" : "formInput1"}>
                                        <input disabled={isDetailsView}
                                               placeholder="Zip Code" {...register("zipCode")} />
                                    </div>
                                    {
                                        !isDetailsView &&
                                        <div style={{textAlign:'center'}}>
                                            <Button style={{backgroundColor:'#391A52', color:'#fff', borderRadius:'35px', border:'0', fontWeight:'600', marginBottom: '2em'}} type={'submit'}>Save Profile</Button>
                                        </div>
                                    }
                                </div>
                        </form>
                    </FormProvider>
                </div>
            }
        </React.Fragment>
    );
};


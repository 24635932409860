import { useEffect, useState } from 'react'
import { brandDetails } from '../data/brandDetails'

export const useGetBrandData = ({ brandName }) => {
    const [desc, setDesc] = useState('')
    const [logo, setLogo] = useState('')
    const [resources, setResources] = useState()
    const [mail, setMail] = useState('')
    const [digitalCareCommunity, setdigitalCareCommunity] = useState('')
    const [trackingNumber, settrackingNumber] = useState('')
    useEffect(() => {
        const brand = brandName.replace('/', '');
        const details = brandDetails[brand]
        if (details) {
            setDesc(details.description)
            setLogo(details.logo)
            setResources(details.resources)
            setMail(details.mail)
            setdigitalCareCommunity(details.digitalCareCommunity)
            settrackingNumber(details.trackingNumber)
        }
    }, [brandName])


    return [desc, logo, resources, mail, digitalCareCommunity, trackingNumber]
}

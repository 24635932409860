import React from 'react'
import { HugGraphic } from '../../Components/Images'
import { useLoaded } from '../../Components/useLoaded'

export const GTLCareHeroSection = ({ path }) => {
    const [loaded] = useLoaded()
    return (
        <React.Fragment>
            <section id="caregiver_hero_section" className='gtl'>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="care_hero_main">
                                    <p className="care_para_1">family caregiver support program</p>
                                    <p className="care_para_2">Who is a Caregiver?</p>
                                    <img src={HugGraphic} alt="HugGraphic" className={"care_hero_img my_animation " + loaded} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="care_description">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="description_para gtl">
                                <p>Caregivers may be a family member, close friend, or a neighbor, who provides regular care to a loved one.</p>
                                <a href={`${path}`}>
                                    Take Caregiver Screener
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ReframeResHeroImg, resourceImg } from '../Components/Images'
import { useLoaded } from '../Components/useLoaded'

export const ResHeroSection = ({ brandName }) => {
    const [loaded] = useLoaded()
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="resource_hero_section" className={brand}>
                    <div className="wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="res_hero_main">
                                        <p className="res_para_1">family caregiver support program</p>
                                        <p className="res_para_2">Resources to Support You</p>
                                        {
                                            brand === 'reframefinancial' ? (
                                                <>
                                                    {/* <img src={ReframeBanner} alt="ReframeBanner" className='reframeBanner' /> */}
                                                    <img src={ReframeResHeroImg} alt="ReframeResHeroImg" className={'res_hero_img my_animation ' + loaded} />
                                                </>
                                            )
                                                :
                                                <img src={resourceImg} alt="resourceImg" className={'res_hero_img my_animation ' + loaded} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
    )
}

import React from "react";
import { GTLBannerRight } from "../../Components/Images";
import { useGetBrandData } from "../../Components/useGetBrandData";

export const GTLHeroSection = ({ brandName }) => {
    const [desc] = useGetBrandData({ brandName });
    return (
        <React.Fragment>
            <section id="gtl_hero_section">
                <div className="wrapper ">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="hero_div">
                                    <div className="hero_left">
                                        <p className="hero_para_1">
                                            family caregiver support program
                                        </p>
                                        <p className="hero_para_2">
                                            Helping loved <br /> ones care better
                                        </p>
                                    </div>
                                    <div className="hero_right">
                                        <img src={GTLBannerRight} alt="hero_bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="hero_description">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {
                                desc && desc !== "" ?
                                    <div className="description_para gtl">
                                        {desc}
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

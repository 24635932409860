import { api, defineCancelApiObject } from "./config"

export const ProfileAPI = {
    checkRegistrationCode: async function (code, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/registration-codes/${code}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.status === 200
    },
    getProfileByEmail: async function (email, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/profiles/${email}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getCompanyById: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/companies/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getProfileById: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/profiles/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getTeamById: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/teams/${id}/members`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    createProfile: async function (body, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/profiles`,
            method: "POST",
            data: body,
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    updateProfile: async function (id, body, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/profiles/${id}`,
            method: "PUT",
            data: body,
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    inviteTeamMember: async function (id, body, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/teams/${id}/members/invite`,
            method: "POST",
            data: body,
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    removeTeamMember: async function (teamId, profileId, cancel = false) {
        const response = await api.request({
            url: `/api/v1/rest/teams/${teamId}/members/${profileId}`,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.get.code].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(ProfileAPI)
import React from 'react'
import { CaregiverPolygon, familyGroupPhoto, familyWheelChair } from '../../Components/Images'

export const GTLCareCardSection = () => {
    return (
        <section id="gtl_care_card_section">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className='outer'>
                            <div className='left'>
                                <p>What does <br /> Caregiving look like?</p>
                                <img src={CaregiverPolygon} alt="CaregiverPolygon" />
                            </div>
                            <div className='right'>
                                <div className='my_card card_1'>
                                    <img src={familyWheelChair} alt="familyWheelChair" />
                                    <p>Caring for <br />an aging parent </p>
                                </div>
                                <div className='my_card card_2'>
                                    <p>Caring <br />for an ill <br />spouse</p>
                                </div>
                                <div className='my_card card_3'>
                                    <img src={familyGroupPhoto} alt="familyGroupPhoto" />
                                    <p>Taking <br />care of a relative with dementia</p>
                                </div>
                                <div className='my_card card_4'>
                                    <p>Supporting <br />a friend through cancer treatment</p>
                                </div>
                                <div className='my_card card_5'>
                                    <p>Caring for <br />a neighbor after a fall</p>
                                    <img src={familyWheelChair} alt="familyWheelChair" />
                                </div>
                                <div className='my_card card_6'>
                                    <img src={familyGroupPhoto} alt="familyGroupPhoto" />
                                    <p>Caring for <br />an elderly relative</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

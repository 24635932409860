import React, { useEffect, useState } from 'react'
import { DownloadApp } from '../Components/DownloadApp'
import { qrCode, fullScreen, qrCodeLarge } from '../Components/Images'

export const GoMobile = ({ brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="go_mobile_section" className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex float-end go_mobile_outer">
                                    <div className="go_mobile_left">
                                        <p className="go_mobile_para">Go mobile to access TCARE anytime for caregiver assistance</p>
                                        <div style={{ width: '191px', float: 'right' }} className='download_app_lg'>
                                            <DownloadApp brandName={brandName} />
                                        </div>
                                        <a href="/#" style={{ marginLeft: '12px' }} data-bs-toggle="modal" data-bs-target="#fullscreenModal" className='d-none fullscreen_btn_mob'>
                                            <img src={fullScreen} alt="fullScreen" width="18" />
                                        </a>
                                    </div>
                                    <div className="d-flex align-items-end qrCode_div" style={{ marginLeft: '22px' }}>
                                        <img src={qrCode} alt="qrCode" className='qrCodeSmall' width="128" />
                                        <a href="/#" style={{ marginLeft: '12px' }} data-bs-toggle="modal" data-bs-target="#fullscreenModal" className='fullscreen_btn_lg'>
                                            <img src={fullScreen} alt="fullScreen" width="18" />
                                        </a>
                                    </div>


                                    <div className="modal fade" id="fullscreenModal" tabIndex={-1} aria-labelledby="fullscreenModalLabel"
                                        aria-hidden="true">
                                        <div className="modal-dialog  modal-dialog-centered mx-auto">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <div className="fullscreen_outer">
                                                        <div className="fullscreen_left">
                                                            <p className="go_mobile_para">Go mobile to access TCARE anytime for
                                                                caregiver assistance</p>

                                                            <div style={{ width: '191px', float: 'right' }}>
                                                                <DownloadApp brandName={brandName} />
                                                            </div>
                                                        </div>

                                                        <img src={qrCodeLarge} alt="qrCodeLarge" className="img-fluid qrCodeLarge" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div style={{ width: '191px' }} className='download_app_mob mx-auto d-none'>
                                    <DownloadApp brandName={brandName} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
    )
}

import { useEffect, useState } from 'react'

export const useBrandName = () => {
    const [brandName, setBrandName] = useState('')
    useEffect(() => {
        const brandName = localStorage.getItem('brand_name');
        if (brandName) {
            setBrandName(brandName)
        }
        else {
            setBrandName('')
        }
        return () => {

        }
    }, [])
    return [brandName]
}

import getBindings from "./config";        // need this if using package
const bindings = getBindings()


export const getMSALConfig = () => ({
       auth: {
           // This is the ONLY mandatory field that you need to supply.
           clientId: bindings.appId,
           // Defaults to "https://login.microsoftonline.com/common"
           authority: bindings.b2cPolicies.authorities.signIn.authority,
           //must identify tenant as authority
           knownAuthorities: [bindings.b2cPolicies.authorityDomain],
           // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
           redirectUri: bindings.redirectUrl,
           // Simply remove this line if you would like navigate to index page after logout
           // postLogoutRedirectUri: bindings.postLogoutRedirectUri,
           // If true, will navigate back to the original request location before processing the auth code response
           navigateToLoginRequestUrl: false,
       },
       cache: {
           // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO btw tabs
           cacheLocation: "sessionStorage",
           // If you wish to store cache items in cookies as well as browser cache, set this to "true"
           storeAuthStateInCookie: false,
       }
})


// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://dev.azure.com/msaltestingjs/_apis/projects?api-version=4.0"
};

// import axios from "axios";
import { useEffect, useState } from "react";
import { brandDetails } from '../data/brandDetails'

export default function useDynamicPath({ dynamicPath }) {
  // console.log(dynamicPath)
  const [path, setpath] = useState("");

  // const data = {
  //   // org_name: params.dynamicPath || "",
  //   org_name: localStorage.getItem("org_name"),
  // };
  // const getData = async () => {
  //   await axios
  //     .post("http://uat-api.mytcare.ai", data, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res)
  //       if (res.data.length === 0) {
  //         localStorage.setItem("org_name", "");
  //         navigate("/");
  //       }
  //       res && res.data.length > 0 && res.data !== "Error"
  //         ? setpath(res.data[0].screener_link)
  //         : setpath("");
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    if (dynamicPath) {
      localStorage.setItem("org_name", dynamicPath);
    } else {
      var org_name = localStorage.getItem("org_name");
      if (org_name) {
      } else {
        localStorage.setItem("org_name", "");
      }
    }

    // const data = {
    //   // org_name: params.dynamicPath || "",
    //   org_name: localStorage.getItem("org_name"),
    // };

    // axios
    //   .post("https://dfd-uat-api.tcare.ai", data, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then((res) => {
    //     // console.log(res)
    //     if (res.data.length === 0) {
    //       localStorage.setItem("org_name", "");
    //     }
    //     res && res.data.length > 0 && res.data !== "Error"
    //       ? setpath(res.data[0].screener_link)
    //       : setpath("");
    //   })
    //   .catch((err) => console.log(err));

    const brandName = localStorage.getItem('brand_name');

    const exists = brandDetails[brandName]
    if (exists) {
        setpath(exists.screener)
    } else {
        setpath("");
    }

  }, [dynamicPath]);

  return [path];
}

import {useIsAuthenticated} from "@azure/msal-react";
import {useEffect, useRef} from "react";
import {getSignInUrl} from "../api/auth";

export const SignInRedirect = () => {
    const isAuthenticated = useIsAuthenticated()
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            const signInUrl = getSignInUrl()
            window.location.href = signInUrl
        }, 1000) // wait one second to ensure branded path is set
    }, [isAuthenticated, inputRef])
}
import React, {useEffect, useState} from "react";
import {useForm, FormProvider, Controller} from "react-hook-form";

import {ProfileAPI} from "../../api/profiles/profiles";

// eslint-disable-next-line
import styles from './styles.css';

import {SignUpCode} from "./SignUpCode";
import {BRANDED_HOME_PATH, getAccount, getBrandedUrl, signIn} from "../../api/auth";
import {useIsAuthenticated} from "@azure/msal-react";
import {validateProfile} from "./inputValidation";
import {navBarBrand} from "../../Components/Images";
import Button from "react-bootstrap/Button";

import ReactSelect from "react-select";
import {genderOptions} from "./data";

export const NewProfile = () => {
    const isAuthenticated = useIsAuthenticated()

    const methods = useForm()
    const {register, handleSubmit, control} = methods;

    const [localAccount, setLocalAccount] = useState(null)
    const [isInvited, setIsInvited] = useState(false)
    const [enteredValidCode, setEnteredValidCode] = useState(0)
    const [teamId, setTeamId] = useState('')
    const [requiresReactivation, setRequiresReactivation] = useState(false)

    // if user has been on page for 3 seconds,
    // and there is still not an active account,
    // re-direct user to sign in
    useEffect(() => {
        setTimeout(() => {
            getAccount().then((account) => {
                if (account === null) {
                    signIn()
                }
            })
        }, 3000);
    }, [])

    useEffect(() => {
        getAccount().then((account) => {
            setLocalAccount(account)
        })
    }, [isAuthenticated])


    const [readyToRender, setReadyToRender] = useState(false)
    useEffect(() => {
        setInviteStatus(localAccount)
        // eslint-disable-next-line
    }, [localAccount])

    function setInviteStatus(account) {
        if (account && account.email) {
            ProfileAPI.getProfileByEmail(account.email, false).then((profile) => {
                if (profile && profile.invited) {
                    setIsInvited(true)
                } else if (profile && !profile.invited && profile.invitedDate !== null) {
                    setRequiresReactivation(true)
                    setIsInvited(false)
                } else {
                    setIsInvited(false)
                }
                setTeamId(profile.teamId)
                setReadyToRender(true)
            }).catch((e) => {
                setIsInvited(false)
            })
        } else if (account && account.emails && account.emails.length > 0) {
            ProfileAPI.getProfileByEmail(account.emails[0], false).then((profile) => {
                if (profile && profile.invited) {
                    setIsInvited(true)
                } else if (profile && !profile.invited && profile.invitedDate !== null) {
                    setRequiresReactivation(true)
                    setIsInvited(false)
                } else {
                    setIsInvited(false)
                }
                setTeamId(profile.teamId)
                setReadyToRender(true)
            }).catch((e) => {
                setIsInvited(false)
            })
        }
    }

    useEffect(() => {
        if (requiresReactivation) {
            ProfileAPI.getTeamById(teamId).then((team) => {
                const benefitRecipient = team.find(teamMember => teamMember.isBenefitRecipient === true)
                setErrorMessage('Please ask ' + benefitRecipient.firstName + ' ' + benefitRecipient.lastName + ' to invite you again onto the care team.')
            })
        }
    }, [requiresReactivation, teamId])

    const [errorMessage, setErrorMessage] = useState('')
    const onSubmit = (data) => {
        let address = {
            "addressLine1": data.addressLine1 === '' ? null : data.addressLine1,
            "addressLine2": data.addressLine2 === '' ? null : data.addressLine2,
            "city": data.city === '' ? null : data.city,
            "county": data.county === '' ? null : data.county,
            "state": data.state === '' || data.state === null ? null : data.state.toUpperCase(),
            "country": 'US', // default for now
            "zipCode": data.zipCode === '' ? null : data.zipCode,
        }
        let newProfile = {
            "firstName": localAccount.given_name,
            "lastName": localAccount.family_name,
            "id": localAccount.oid ? localAccount.oid : localAccount.sub,
            "email": localAccount.emails ? localAccount.emails[0] : localAccount.email, // different for sign up / sign in
            "gender": data.gender ? data.gender.value : null,
            "birthDate": data.birthDate === '' ? null : data.birthDate,
            "phone": data.phone === '' ? null : data.phone,
            "address": address,
        }

        if (enteredValidCode) {
            newProfile.registrationCode = data.code
            newProfile.isBenefitRecipient = true
            newProfile.employeeWorkEmail = data.employeeWorkEmail.toLowerCase()
        }

        let errorMessage = validateProfile(newProfile)
        if (errorMessage && errorMessage !== '') {
            setErrorMessage(errorMessage)
            return
        } else {
            setErrorMessage('')
        }


        ProfileAPI.createProfile(newProfile).then((newProfile) => {
            if (newProfile && newProfile.companyId) {
                ProfileAPI.getCompanyById(newProfile.companyId).then((company) => {
                    if (company && company.brandedHomePath) {
                        localStorage.setItem(BRANDED_HOME_PATH, company.brandedHomePath)
                        const brandedUrl = getBrandedUrl('profile') // todo: use constant from auth.js
                        window.location.href = brandedUrl
                    }
                })
            }
            // todo: redirect to non branded page?
        }).catch((e) => {
            // at time of mvp, this occurs when a user creates an account,
            // gets deleted from active directory but exists in profile database
            // and tries to create another account
            if (e.response.status === 409) {



                setErrorMessage(`To reactivate your profile, please contact support.`)
            }
            console.error(e)
        })
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: 42.45,
            minHeight: 42.45,
            borderColor: '#391A52',
        }),
    };

    return (

        readyToRender &&
        localAccount &&
        <React.Fragment>
            {
                requiresReactivation ?
                    <div className="App2">
                        <div style={{textAlign: 'center', marginTop: '2em'}}>
                            <img src={navBarBrand} alt="navBarBrand" className='footerLogo' />
                                <div style={{color:'red', display:'flex', flexDirection:'column',justifyContent:'center',textAlign:'left', marginTop:'3em', fontSize:'18px'}}>
                                    <p>All apologies!  We are unable to fully register your MyTCARE account at the moment, because you were removed from the care team.</p>
                                    <br/>
                                    <p>{errorMessage}</p>
                                        <br/>
                                    <p>If still having issues, feel free to send an email with details to <a href="mailto:WeCare@MyTCARE.ai">WeCare@MyTCARE.ai</a>.</p>
                                </div>
                        </div>
                    </div>
                    :
                localAccount &&
                <div className="App2">
                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                        <img src={navBarBrand} alt="navBarBrand" className='footerLogo' />
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {!isInvited &&
                                <SignUpCode setEnteredValidCode={setEnteredValidCode}/>}

                            {
                                <div hidden={!(isInvited || enteredValidCode)}
                                     className={"formContainer1"}>

                                    <div style={{color:'red', display:'flex', flexDirection:'column', alignItems:'center'}}>
                                        <h5 hidden={errorMessage === ''} >{errorMessage}</h5>
                                    </div>
                                    <div className={"formInput1"}>
                                        <input hidden={isInvited}
                                               placeholder="Enter your work email address." {...methods.register("employeeWorkEmail")} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div className={'create'} style={{flex: '1', width: '50%', color:'red'}} >
                                            <input type={"date"} placeholder={'Birth Date'} {...methods.register("birthDate")} />
                                        </div>
                                        <div style={{flex: '1', width: '50%', marginLeft:'.5em'}} >
                                            <Controller
                                                name='gender'
                                                control={control}
                                                render={({ field }) => (
                                                    <ReactSelect
                                                        placeholder={'Gender'}
                                                        {...field}
                                                        options={genderOptions}
                                                        styles={customStyles}
                                                    ></ReactSelect>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={"formInput1"}>
                                        <input
                                               placeholder="Phone Number" {...methods.register("phone")} />
                                    </div>
                                    <div className={"formInput1"}>
                                        <input
                                               placeholder="Street Address" {...register("addressLine1")} />
                                    </div>
                                    <div className={"formInput1"}>
                                        <input
                                               placeholder="Apt, suite, etc. (optional)" {...register("addressLine2")} />
                                    </div>
                                    <div className={"formInput1"}>
                                        <input placeholder="City" {...register("city")} />
                                    </div>
                                    <div className={"formInput1"}>
                                        <input placeholder="State" {...register("state")} />
                                    </div>
                                    <div className={"formInput1"}>
                                        <input placeholder="Zip Code" {...register("zipCode")} />
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        <Button style={{backgroundColor:'#391A52', color:'#fff', borderRadius:'35px', border:'0', fontWeight:'600'}} type={'submit'}>Save Profile</Button>
                                    </div>
                                </div>
                            }
                        </form>
                    </FormProvider>
                </div>
            }
        </React.Fragment>
    );
};


import React from 'react'
import { useLoaded } from '../Components/useLoaded'
import { careInsights, educationRedBg, ReframeCareInsightHeroImg } from '../Components/Images'
import { useEffect } from 'react'
import { useState } from 'react'

export const CareInsightsHeroSection = ({ brandName }) => {
    const [loaded] = useLoaded()
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id='careInsight_hero_section' className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="careInsight_hero_main">
                                    <p className="careInsight_para_1">family caregiver support program</p>
                                    <p className="careInsight_para_2">Caregiving Insights</p>
                                    {
                                        brand === 'reframefinancial' ?
                                            <img src={ReframeCareInsightHeroImg} alt="ReframeCareInsightHeroImg" className={'careInsights_img my_animation ' + loaded} />
                                            :
                                            <img src={careInsights} alt="careInsights" className={"careInsights_img my_animation " + loaded} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    brand === 'reframefinancial' ? '' :
                        <section id="careInsight_hero_description" className={brand}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="description_para">
                                            <img src={educationRedBg} alt="educationRedBg" />
                                            <p>Curated from TCARE experts. Check out our resources for your caregiving needs as well as posts
                                                directly from the TCARE team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }

            </React.Fragment>
    )
}

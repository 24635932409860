import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBrandName } from '../Components/useBrandName'
import useDynamicPath from '../Components/useDynamicPath'
import { Footer } from '../Layouts/Footer'
import { GoMobile } from '../Layouts/GoMobile'
import { GTLResHeroSection } from '../Layouts/GTL/GTLResHeroSection'
import { Header } from '../Layouts/Header'
import { ResHeroSection } from '../Layouts/ResHeroSection'
import { ResMainSection } from '../Layouts/ResMainSection'

export const Resources = () => {
  const { dynamicPath } = useParams()

  var [brandName] = useBrandName();
  brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


  const [path] = useDynamicPath({
    dynamicPath
  })

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [brandName])
  return (
    loading ? '' :
      <React.Fragment>
        {
          brandName && brandName === '/gtl' ?
            <>
              <Header path={path} brandName={brandName} />
              <GTLResHeroSection />
              <ResMainSection path={path} brandName={brandName} />
              <GoMobile brandName={brandName} />
              <Footer brandName={brandName} />
            </>
            :
            brandName && brandName === '/reframefinancial' ?
              <>
                <Header path={path} brandName={brandName} />
                <ResHeroSection brandName={brandName} />
                <ResMainSection path={path} brandName={brandName} />
                <GoMobile brandName={brandName} />
                <Footer brandName={brandName} />
              </>
              :
              <>
                <Header path={path} brandName={brandName} />
                <ResHeroSection brandName={brandName} />
                <ResMainSection path={path} brandName={brandName} />
                <GoMobile brandName={brandName} />
                <Footer brandName={brandName} />
              </>
        }
      </React.Fragment>
  )
}

import {useIsAuthenticated} from "@azure/msal-react";
import {useEffect, useRef} from "react";
import {getBrandedUrl} from "../api/auth";

export const SignUpRedirect = () => {
    const isAuthenticated = useIsAuthenticated()
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            const signUpUrl = getBrandedUrl('create-profile')
            window.location.href = signUpUrl
        }, 1000)
    }, [isAuthenticated, inputRef])
}
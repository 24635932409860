import React from 'react'
import { readmore } from './Images'

export const CareInsightCard = ({ src, alt, desc, url }) => {
    return (
        <React.Fragment>
            <div className='careInsightCard mx-auto'>
                <div className='careInsightCard_head'>
                    <img src={src} alt={alt} />
                </div>

                <div className='careInsightCard_body'>
                    <a href={url} className='careInsightCardlink'>
                        <p>{desc}</p>
                        <div className='readmore'>Read More <span><img src={readmore} alt="readmore" /></span></div>
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

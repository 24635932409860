import React, { useEffect, useState } from 'react'
import { footerLogo, facebookImg, linkedInImg } from '../Components/Images'
import { useGetBrandData } from '../Components/useGetBrandData';

export const Footer = ({ brandName }) => {
    const [, , , , , trackingNumber] = useGetBrandData({ brandName });
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <footer style={{ overflow: 'hidden' }} className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <a href={brandName === '' ? '/' : brandName}>
                                    <img src={footerLogo} alt="footerLogo" className='footerLogo' />
                                </a>
                            </div>
                        </div>

                        <div className="row copyRight_row">
                            <div className="col">
                                {
                                    brandName && brandName === '/gtl' ?
                                        <p className="copyRight_para gtl"> @ 2022 TCARE, Inc. All rights Reserved.&nbsp;<a
                                            href="https://www.tcare.ai/content/privacy">Privacy Policy</a></p>
                                        :
                                        <p className="copyRight_para"> @ 2022 TCARE, Inc. All rights Reserved. <a
                                            href="https://www.tcare.ai/content/privacy">Privacy Policy</a></p>
                                }
                                {
                                    trackingNumber && <p className='copyRight_para'>{trackingNumber}</p>

                                }
                            </div>

                            <div className="col-lg-1 float-end p-0 social_media">
                                <a href="https://www.facebook.com/TailoredCARE/">
                                    <img src={facebookImg} alt="facebookImg" />
                                </a>
                                <a href="https://www.linkedin.com/company/tcareinc/">
                                    <img src={linkedInImg} alt="linkedInImg" className="linkedIn" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
    )
}

import React, { useEffect, useState } from 'react'
import { careGiver, ReframeCareGiverHeroFamily } from '../Components/Images'
import { useLoaded } from '../Components/useLoaded'

export const CareHeroSection = ({ path, brandName }) => {
    const [loaded] = useLoaded() // for image animation
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="caregiver_hero_section" className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="care_hero_main">
                                    <p className="care_para_1">family caregiver support program</p>
                                    <p className="care_para_2">Who is a Caregiver?</p>
                                    {
                                        brand === 'reframefinancial' ? (
                                            <>
                                                <img src={ReframeCareGiverHeroFamily} alt="careGiver" className={"care_hero_img my_animation " + loaded} />
                                                {/* <img src={ReframeBanner} alt="ReframeBanner" className='reframeBanner' /> */}
                                            </>
                                        ) :

                                            <img src={careGiver} alt="careGiver" className={"care_hero_img my_animation " + loaded} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    // for reframefinancial this content is available in CareCardSection.js
                    brand === 'reframefinancial' ? '' :
                        <section id="care_description" className={brand}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="description_para">
                                            <p>A caregiver is anyone who is providing regular care to someone who needs assistance taking
                                                care of themselves.</p>
                                            <a href={`${path}`}>
                                                Take Caregiver Screener
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </React.Fragment>
    )
}

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './Components/ScrollToTop';
import { useBrandName } from './Components/useBrandName';
import { ApplicationDownload } from './Pages/ApplicationDownload';
import { Attachment } from './Pages/Attachment';
import { CaregiverBurdens } from './Pages/CaregiverBurdens';
import { CaregiverInsights } from './Pages/CaregiverInsights';
import { CaregivingChangesIdentity } from './Pages/CaregivingChangesIdentity';
import { Contact } from './Pages/Contact';
import { Home } from './Pages/Home';
import { Resources } from './Pages/Resources';
import { WhoIsACareGiver } from './Pages/WhoIsACareGiver';

import React from "react";
import { Team } from "./Pages/Teams/Team";
import { NewProfile } from "./Pages/Profiles/NewProfile";
import { ExistingProfile } from "./Pages/Profiles/ExistingProfile";
import { PreSignUpRedirect } from "./Components/PreSignUpRedirect";
import { SignOutRedirect } from "./Components/SignOutRedirect";
import { SignInRedirect } from "./Components/SignInRedirect";
import { SignUpRedirect } from "./Components/SignUpRedirect";
import { RecoverCash } from './Pages/RecoverCash';
import { useGetBrandData } from './Components/useGetBrandData';

function App() {
  const [brandName] = useBrandName();
  const [, , resources] = useGetBrandData({ brandName });
  return (

    <BrowserRouter basename={brandName}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post-signin" element={<SignInRedirect />} />
          <Route path="/post-signout" element={<SignOutRedirect />} />
          <Route path="/post-signup" element={<SignUpRedirect />} />
          <Route path="/whoIsACareGiver" element={<WhoIsACareGiver />} >
            <Route path=":dynamicPath" element={<WhoIsACareGiver />} />
          </Route>
          <Route path="/resources" element={<Resources />} >
            <Route path=":dynamicPath" element={<Resources />} />
          </Route>
          <Route path=":dynamicPath/*" element={<Home />} />
          {
            brandName && brandName === 'woodhuston' ? <Route path='/EAP_POSTER_PDF_ATTACHMENT' element={<Attachment attachment={resources && resources.attachment} />} /> : ''
          }
          {
            brandName && brandName === 'gtl' ?
              <>
                <Route path='/resources/recover-cash' element={<RecoverCash />} />
                <Route path='/resources/Recover-Cash-Product-Brochure' element={<Attachment attachment={resources && resources.brochure} />} />
                <Route path='/resources/Recover-Cash-Product-Infographic' element={<Attachment attachment={resources && resources.infographic} />} />
              </>

              : ''
          }
          {
            brandName !== 'buddyins' ? <Route path="/contact" element={<Contact />} /> : ''
          }
          <Route path="/signup" element={<PreSignUpRedirect />} />
          <Route path="/create-profile" element={<NewProfile />} />
          <Route path="/profile" element={<ExistingProfile />} />
          <Route path="/team" element={<Team />} />
          <Route path="/caregiverInsights" element={<CaregiverInsights />} />
          <Route path="/caregiverInsights/caregiving-changes-your-identity" element={<CaregivingChangesIdentity />} />
          <Route path="/caregiverInsights/caregiver-burdens" element={<CaregiverBurdens />} />

          <Route path='/app' element={<ApplicationDownload />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;


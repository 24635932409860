import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { careInsightMain, readmore, ReframeEducationBlue } from '../Components/Images'

export const CareInsightsMainSection = ({ brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id='combined_careInsight_hero_description' className={brand}>
                    {
                        brand === 'reframefinancial' ?
                            <section id="careInsight_hero_description" className={brand}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="description_para">
                                                <img src={ReframeEducationBlue} alt="ReframeEducationBlue" />
                                                <p>Curated from TCARE experts. <br /> Check out our resources for your caregiving needs as well as posts
                                                    directly from the TCARE team.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            ''
                    }
                    <section id='careInsight_main_section' className={brand}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className='careInsightMain_outer'>
                                        <div className='overlay_bg'></div>
                                        <div className='careInsightMain_left'>
                                            <h2>10 Ways Caregivers Can Improve Their Own Self-Care</h2>
                                            {
                                                brand === 'reframefinancial' ?
                                                    <p>This will be the first couple sentences of the post from the article or TCARE blog post for three or four lines of summary or until it hits a breakpoint or runs out of...</p>
                                                    :
                                                    <p>Self-care, White says, is the fine art of prioritizing your own needs even as you look out for the well-being of a loved one. This is where most caregivers fail.</p>
                                            }
                                            <a href="https://www.aarp.org/caregiving/life-balance/info-2022/improve-self-care.html" className='readmore'>Read More <span><img src={readmore} alt="readmore" /></span></a>
                                        </div>

                                        <div className='careInsightMain_right'>
                                            <img src={careInsightMain} alt="careInsightMain" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id='careInsightCard_section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto">
                            <CareInsightCard 
                                src={howCaregiving_small}
                                alt='howCaregiving_small'
                                desc='How Caregiving Changes Your Identity'
                                url='/caregiverInsights/caregiving-changes-your-identity'
                            />
                        </div>
                        <div className="col-md-4 mx-auto">
                            <CareInsightCard 
                                src={burdens_small}
                                alt='burdens_small'
                                desc='Caregiver Burdens'
                                url='/caregiverInsights/caregiver-burdens'
                            />
                        </div>
                        <div className="col-md-4 mx-auto">
                            <CareInsightCard 
                                src={neighbour}
                                alt='neighbour'
                                desc='When Neighbors and Friends Become Caregivers'
                                url=''
                            />
                        </div>
                    </div>
                </div>
            </section> */}
                </section>
            </React.Fragment>
    )
}

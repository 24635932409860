import {useIsAuthenticated} from "@azure/msal-react";
import {useEffect, useRef} from "react";
import {signUp} from "../api/auth";

export const PreSignUpRedirect = () => {
    const isAuthenticated = useIsAuthenticated()
    const inputRef = useRef()

    useEffect(() => {
        signUp()
    }, [isAuthenticated, inputRef])
}
import React from 'react'
import { useParams } from 'react-router-dom';
import { useBrandName } from '../Components/useBrandName';
import useDynamicPath from '../Components/useDynamicPath';
import { Footer } from '../Layouts/Footer';
import { GoMobile } from '../Layouts/GoMobile';
import { GTLRecCashHeroSection } from '../Layouts/GTL/GTLRecCashHeroSection';
import { GTLRecCashMainSection } from '../Layouts/GTL/GTLRecCashMainSection';
import { Header } from '../Layouts/Header';

export const RecoverCash = () => {
    const { dynamicPath } = useParams()

    var [brandName] = useBrandName();
    brandName && brandName !== "" ? brandName = '/' + brandName : brandName = '';


    const [path] = useDynamicPath({
        dynamicPath
    })
    return (
        <React.Fragment>
            <Header path={path} brandName={brandName} />
            <GTLRecCashHeroSection />
            <GTLRecCashMainSection brandName={brandName} />
            <GoMobile brandName={brandName} />
            <Footer brandName={brandName} />
        </React.Fragment>
    )
}

import { useState } from 'react';
import { useEffect } from 'react'
import { brandDetails } from '../data/brandDetails';

export const useSetBrand = ({ dynamicPath }) => {
    // console.log(dynamicPath)
    var [brandPath] = useState(dynamicPath)
    useEffect(() => {
        if (brandPath) {
            localStorage.setItem("brand_name", brandPath);
        } else {
            localStorage.setItem("brand_name", "");
            localStorage.setItem("org_name", "");
        }

        const brandName = localStorage.getItem('brand_name');

        // if (brandName === 'buddyins') {
        //     localStorage.setItem("brand_name", brandName);
        // }
        // else {
        //     localStorage.setItem("brand_name", "");
        // }

        const exists = brandDetails[brandName]
        if (exists) {
            localStorage.setItem("brand_name", brandName);
        } else {
            localStorage.setItem("brand_name", "");
            localStorage.setItem("org_name", "");
        }

    }, [brandPath])
    // useEffect(() => {
    //     if (dynamicPath) {
    //         localStorage.setItem("brand_name", dynamicPath);
    //     }

    //     const data = {
    //         brand_name: localStorage.getItem("brand_name"),
    //     };

    //     brandDetails.map((details, key) => {
    //         if (data.brand_name === details.brandName) {
    //             localStorage.setItem("brand_name", details.brandName);
    //             return true
    //         }
    //         return false

    //     })
    // }, [dynamicPath]);
    // return null
    return null
}

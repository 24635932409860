export const brandDetails_uat = {
    "": {
        brandName: "",
        // description:
        //     "Wherever you are in your caregiving journey, TCARE is here to provide you and your family with the tools, resources, and support so you can focus on what matters most - caring for your loved ones and yourself.",
        logo: '',
        screener: "https://caregiver.tcare.ai/screener/sandbox/empbenefits/kT0CCVAZsc15YK6j51P4aGnK",
        mail: 'employergroups@tcare.ai',
        digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare'
    },
    buddyins: {
        brandName: "buddyins",
        description:
            "As a member of the BuddyIns community, you have access to the TCARE caregiver support digital resources. Wherever you are in your caregiving journey, TCARE is here to provide you and your family with the tools, support and services so you can focus on what matters most – caring for your loved ones and yourself.",
        logo: 'buddyInsLogo',
        screener: "https://screener.mytcare.ai/screener/sandbox/buddyins/pzwOTXy499VE5Yo4lXUh4F99",
        mail: 'buddyins@tcare.ai',
        resources: {
            heading: "BuddyIns Community Partners",
            headingUrl: 'https://www.buddyins.com/community/community-partners/',
            subPara: "Connect with other BuddyIns Community Partners for guidance on your long term care insurance claim process, finding the right long term care facility, or for more information on alternative long term care insurance options.",
            additionalContent: '',
            attachment: ''
        }
    },
    woodhuston: {
        brandName: "woodhuston",
        description:
            "Wood & Huston recognizes many employees are caring for loved ones and taking on the challenges that come with this responsibility. Wherever you are in your caregiving journey, the TCARE Family Caregiver Program is here to provide you and your family with the tools, resources, and support so you can focus on what matters most – caring for your loved ones and yourself.",
        logo: 'woodhustonLogo',
        screener: "https://screener.mytcare.ai/screener/sandbox/woodhuston/W78erks3D1QWNRtyq34url8T",
        mail: 'woodhuston@tcare.ai',
        digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
        resources: {
            heading: "Employee Assistance Program (EAP)",
            headingUrl: 'EAP_POSTER_PDF_ATTACHMENT',
            subPara: "Your EAP offered through Wood & Huston provides a variety resources to support life’s ups and downs, at no cost to you.",
            additionalContent: "If you have any questions about your Wood & Huston benefits, please contact:",
            contactDetails: [
                {
                    name: 'Shari Sheridan, Client Service Manager',
                    email: 'ssheridan@bukaty.com',
                    phone: '913-258-2239'
                },
                {
                    name: 'Patty Falke, Account Coordinator',
                    email: 'pfalke@bukaty.com',
                    phone: '913-777-7546'
                },
                {
                    name: 'Kent Friend, National Benefits Consultant',
                    email: 'kfriend@bukaty.com',
                    phone: '913-777-7535'
                },


            ],
            attachment: 'WH-EAP-Poster'
        }
    },
    tailoredcare: {
        brandName: "tailoredcare",
        description: "",
        logo: "tailoredcareLogo",
        screener: "https://screener.mytcare.ai/screener/sandbox/empbenefits/kT0CCVAZsc15YK6j51P4aGnK",
        mail: "employergroups@tcare.ai",
        digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
    },
    gtl: {
        brandName: "gtl",
        description: <p>Providing care for a loved one is rewarding but can be challenging. TCARE is <br /> here to support you with the tools and resources to prevent the all-too-common caregiver burnout and help you focus on what matters most - caring for your loved one and yourself."</p>,
        logo: "gtlLogo",
        screener: "https://screener.mytcare.ai/screener/sandbox/gtl/TS8aeQoAn7t4Ri7S9FdmiyRq",
        mail: "GuaranteeTrustLife@tcare.ai",
        // digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
        resources: {
            heading: "Recover Cash Benefit Information",
            headingUrl: 'resources/recover-cash',
            subPara: "Access information regarding the GTL Recover Cash policy including product brochures and video.",
            // additionalContent:"",
            gtlAdditionalContent: <p className="res_main_para pt-0">For GTL Recover Cash policyholder or claims inquires please call <b>1-800-338-7452</b> or visit the GTL website at <b><a href="https://www.gtlic.com/file-a-claim/" style={{ color: 'var(--darkPurple)' }}>https://www.gtlic.com/file-a-claim/</a></b></p>,
            brochure: 'Recover-Cash-Product-Brochure',
            infographic: 'Recover-Cash-Product-Infographic'

        },
        trackingNumber: 'GAD29.8-22'
    },
    reframefinancial: {
        brandName: "reframefinancial",
        description: <p className='coBrandDesc'>Reframe understands that caring for an aging loved one is rewarding but also comes with emotional and financial challenges. To help you navigate your role as a family caregiver we have partnered with TCARE to offer a Family Caregiver Support benefit, included in your policy at no additional cost to you. TCARE is here to provide you and your family with the tools, resources, and support so you can focus on what matters most &mdash; caring for your loved ones and yourself.</p>,
        logo: "reframeLogo",
        screener: "https://screener.mytcare.ai/screener/sandbox/reframefinancial/HNkRiEURslD8W9FBrHJrPGws",
        mail: "reframefinancial@tcare.ai",
        // digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
        resources: {
            heading: "Reframe LifeStage Benefit Information",
            headingUrl: 'https://www.reframefinancial.com/',
            subPara: "Access additional information regarding the Reframe LifeStage insurance product.",
            // additionalContent:"",
            // gtlAdditionalContent: <p className="res_main_para pt-0">For GTL Recover Cash policyholder or claims inquires please call <b>1-800-338-7452</b> or visit the GTL website at <b><a href="https://www.gtlic.com/file-a-claim/" style={{ color: 'var(--darkPurple)' }}>https://www.gtlic.com/file-a-claim/</a></b></p>,
            // brochure: 'Recover-Cash-Product-Brochure',
            // infographic: 'Recover-Cash-Product-Infographic'

        },
        trackingNumber: ''
    }
};

export const brandDetails_prod = {
    "": {
        brandName: "",
        // description:
        //     "Wherever you are in your caregiving journey, TCARE is here to provide you and your family with the tools, resources, and support so you can focus on what matters most - caring for your loved ones and yourself.",
        logo: '',
        screener: "https://caregiver.tcare.ai/screener/sandbox/empbenefits/kT0CCVAZsc15YK6j51P4aGnK",
        mail: 'employergroups@tcare.ai',
        digitalCareCommunity: 'https://www.caregiving.com/onelink/tcare'
    },
    buddyins: {
        brandName: "buddyins",
        description:
            "As a member of the BuddyIns community, you have access to the TCARE caregiver support digital resources. Wherever you are in your caregiving journey, TCARE is here to provide you and your family with the tools, support and services so you can focus on what matters most – caring for your loved ones and yourself.",
        logo: 'buddyInsLogo',
        screener: "https://screener.mytcare.ai/screener/app/buddyins/HwB2gUya97FyLnovD5zjqrC9",
        mail: 'buddyins@tcare.ai',
        resources: {
            heading: "BuddyIns Community Partners",
            headingUrl: 'https://www.buddyins.com/community/community-partners/',
            subPara: "Connect with other BuddyIns Community Partners for guidance on your long term care insurance claim process, finding the right long term care facility, or for more information on alternative long term care insurance options.",
            additionalContent: '',
            attachment: ''
        }
    },
    woodhuston: {
        brandName: "woodhuston",
        description:
            "Wood & Huston recognizes many employees are caring for loved ones and taking on the challenges that come with this responsibility. Wherever you are in your caregiving journey, the TCARE Family Caregiver Program is here to provide you and your family with the tools, resources, and support so you can focus on what matters most – caring for your loved ones and yourself.",
        logo: 'woodhustonLogo',
        screener: "https://screener.mytcare.ai/screener/app/woodhuston/UD4zHeqgyoGB8JPJFAVF11cK",
        mail: 'woodhuston@tcare.ai',
        digitalCareCommunity: 'https://www.caregiving.com/onelink/tcare',
        resources: {
            heading: "Employee Assistance Program (EAP)",
            headingUrl: 'EAP_POSTER_PDF_ATTACHMENT',
            subPara: "Your EAP offered through Wood & Huston provides a variety resources to support life’s ups and downs, at no cost to you.",
            additionalContent: "If you have any questions about your Wood & Huston benefits, please contact:",
            contactDetails: [
                {
                    name: 'Shari Sheridan, Client Service Manager',
                    email: 'ssheridan@bukaty.com',
                    phone: '913-258-2239'
                },
                {
                    name: 'Patty Falke, Account Coordinator',
                    email: 'pfalke@bukaty.com',
                    phone: '913-777-7546'
                },
                {
                    name: 'Kent Friend, National Benefits Consultant',
                    email: 'kfriend@bukaty.com',
                    phone: '913-777-7535'
                },


            ],
            attachment: 'WH-EAP-Poster'
        }
    },
    tailoredcare: {
        brandName: "tailoredcare",
        description: "",
        logo: "tailoredcareLogo",
        screener: "https://screener.mytcare.ai/screener/app/empbenefits/XE2Cfrfh2FvoJ6QG4sc6ZHDI",
        mail: "employergroups@tcare.ai",
        digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
    },
    gtl: {
        brandName: "gtl",
        description: <p>Providing care for a loved one is rewarding but can be challenging. TCARE is <br /> here to support you with the tools and resources to prevent the all-too-common caregiver burnout and help you focus on what matters most - caring for your loved one and yourself."</p>,
        logo: "gtlLogo",
        screener: "https://screener.mytcare.ai/screener/app/gtl/JAE0am1Qr38G2wM5Yi4MYv52",
        mail: "GuaranteeTrustLife@tcare.ai",
        // digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
        resources: {
            heading: "Recover Cash Benefit Information",
            headingUrl: 'resources/recover-cash',
            subPara: "Access information regarding the GTL Recover Cash policy including product brochures and video.",
            // additionalContent:"",
            gtlAdditionalContent: <p className="res_main_para pt-0">For GTL Recover Cash policyholder or claims inquires please call <b>1-800-338-7452</b> or visit the GTL website at <b><a href="https://www.gtlic.com/file-a-claim/" style={{ color: 'var(--darkPurple)' }}>https://www.gtlic.com/file-a-claim/</a></b></p>,
            brochure: 'Recover-Cash-Product-Brochure',
            infographic: 'Recover-Cash-Product-Infographic'

        },
        trackingNumber: 'GAD29.8-22'
    },
    reframefinancial: {
        brandName: "reframefinancial",
        description: <p className='coBrandDesc'>Reframe understands that caring for an aging loved one is rewarding but also comes with emotional and financial challenges. To help you navigate your role as a family caregiver we have partnered with TCARE to offer a Family Caregiver Support benefit, included in your policy at no additional cost to you. TCARE is here to provide you and your family with the tools, resources, and support so you can focus on what matters most &mdash; caring for your loved ones and yourself.</p>,
        logo: "reframeLogo",
        screener: "https://screener.mytcare.ai/screener/app/reframefinancial/wXUTBedzxG3H8W3kgH5w1vv8",
        mail: "reframefinancial@tcare.ai",
        // digitalCareCommunity: 'https://www.caregiving.dev/onelink/tcare',
        resources: {
            heading: "Reframe LifeStage Benefit Information",
            headingUrl: 'https://www.reframefinancial.com/',
            subPara: "Access additional information regarding the Reframe LifeStage insurance product.",
            // additionalContent:"",
            // gtlAdditionalContent: <p className="res_main_para pt-0">For GTL Recover Cash policyholder or claims inquires please call <b>1-800-338-7452</b> or visit the GTL website at <b><a href="https://www.gtlic.com/file-a-claim/" style={{ color: 'var(--darkPurple)' }}>https://www.gtlic.com/file-a-claim/</a></b></p>,
            // brochure: 'Recover-Cash-Product-Brochure',
            // infographic: 'Recover-Cash-Product-Infographic'

        },
        trackingNumber: ''
    }
};

export const brandDetails = process.env.REACT_APP_ENV === "production" ? brandDetails_prod : brandDetails_uat

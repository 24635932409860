import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from '@mui/icons-material/Error';
import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {useFormContext} from "react-hook-form";
import {ProfileAPI} from "../../api/profiles/profiles";

import Tooltip from '@mui/material/Tooltip';

export const SignUpCode = ({setEnteredValidCode}) => {
    const {register, handleSubmit} = useFormContext()

    // enteredCodeStatus values and their respective situations
    // 0: no input
    // 1: invalid code
    // 2: valid code
    const [enteredCodeStatus, setEnteredCodeStatus] = useState(0);

    const onSubmitRegistrationCode = (data) => {
        if (data && data.code) {
            ProfileAPI.checkRegistrationCode(data.code, false).then((validCode) => {
                if (validCode) {
                    setEnteredValidCode(true)
                    setEnteredCodeStatus(2)
                }
            }).catch((e) => {
                if (e.response.status === 404) {
                    setEnteredCodeStatus(1) // 404 means code not found (thus invalid)
                }
                // todo: show user a general error message + notify dev team
            })
        }
    };

    const onEnterRegistrationCode = (event) => {
        if (event && event.keyCode === 13) { // 13: enter key
            event.preventDefault()
            handleSubmit(onSubmitRegistrationCode)()
        }
    }

    return (
        <div>
            <div  className={"formInput1"}>
                {
                    enteredCodeStatus === 2 ?
                        <div className={"textAndIcon"}>
                            <label htmlFor="code" style={{color: "#391A52", fontSize:"17px", fontWeight:'500'}}>Registration Code</label>
                            <VerifiedIcon style={{color: "#391A52", marginLeft: ".25em", height: "17px", width: "17px", marginTop:".25em"}}></VerifiedIcon>
                        </div>
                        :
                        <label htmlFor="code" style={{color: "#391A52", fontWeight:"700", textAlign: 'center', fontSize:"17px", marginTop: '2em'}}>Verify your registration code.</label>
                }

                <div>
                    <input onKeyDown={onEnterRegistrationCode} placeholder='Enter your registration code to continue.' disabled={enteredCodeStatus===2} hidden={enteredCodeStatus===2} {...register('code')} autoFocus={true}  />
                </div>
            </div>
            {
                enteredCodeStatus === 1 && (
                    <div style={{marginTop:'-2em'}}>
                        <Tooltip title={'Enter the registration code provided by your employer. If you are not sure what your registration code is, please contact your human resources representative.'}>
                            <div style={{display:'flex', flexDirection: 'row', alignItems:'center', marginTop:'-.5em'}}>
                                <ErrorIcon style={{color: "red",  height: "17px", width: "17px", marginTop:'.25em'}}></ErrorIcon>
                                <label style={{marginLeft: ".25em", color: "red", fontSize:"17px", fontWeight:'400'}}>Enter a valid registration code.</label>
                            </div>
                        </Tooltip>
                    </div>
                )
            }
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px', fontWeight: '400', textAlign: 'center' }}>
                <p>Please note, registration codes are <u><b>Case Sensitive</b></u>.</p>
            </div>
            {
                (enteredCodeStatus !== 2) &&
                <div style={{textAlign:'center'}}>
                    <Button style={{backgroundColor:'#391A52', color:'#fff', borderRadius:'35px', border:'0', fontWeight:'600'}} disabled={enteredCodeStatus===2} onClick={handleSubmit(onSubmitRegistrationCode)}>Verify Code</Button>
                </div>
            }
        </div>
    )
}
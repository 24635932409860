export const validateProfileUpdate = (profile) => {
    if (!profile) {
        return 'missing profile'
    } else if (!isValidName(profile.firstName)) {
        return 'Please enter your first name.'
    } else if (!isValidName(profile.lastName)) {
        return 'Please enter your last name.'
    }
    return validateProfile(profile)
}

export const validateProfile = (profile) => {
    if (!profile) {
        return 'missing profile'
    } else if (profile.isBenefitRecipient && !isValidEmail(profile.employeeWorkEmail)) {
        return 'Please enter a valid work email.'
    } else if (profile.address && profile.address.state && !isValidState(profile.address.state)) {
        return 'State must be a valid two-letter state abbreviation.'
    } else if (profile.address && profile.address.zipCode && !isValidZip(profile.address.zipCode)) {
        return 'Zip Code must be a valid five-digit zip code.'
    } else if (profile.phone && !isValidPhone(profile.phone)) {
        return 'Phone must be a valid ten-digit phone number.'
    } else if (profile.birthDate && validateBirthYear(profile.birthDate)) {
        return validateBirthYear(profile.birthDate)
    }
    return null
}

const isValidName = (name) => {
    return name && name !== '' && name.length >= 3
}

const validateBirthYear = (birthDate) => {
    const year =  birthDate.substring(0,birthDate.indexOf('-'))
    const earliestYear = '1920'
    if (year.length > 4) {
        return 'Please enter your birth date.'
    } else if (year > '2013') {
        return 'You do not meet the minimum age requirements.'
    } else if (birthDate === '' || year < earliestYear) {
        return 'Please enter your birth date.'
    }
    return null
}

// eslint-disable-next-line no-useless-escape
export const isValidPhone = phone => /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/.test(phone)

// eslint-disable-next-line no-useless-escape
export const isValidZip = zipCode => /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode)

export const isValidState = state => /^[A-Z]{2}$/.test(state)

export const isValidEmail = email =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
import React from 'react'
import { useLoaded } from '../../Components/useLoaded'
import { careInsights, educationRedBg } from '../../Components/Images'

export const GTLCareInsightsHeroSection = () => {
    const [loaded] = useLoaded()
    return (
        <React.Fragment>
            <section id='careInsight_hero_section' className='gtl'>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="careInsight_hero_main">
                                    <p className="careInsight_para_1">family caregiver support program</p>
                                    <p className="careInsight_para_2">Caregiving Insights</p>
                                    <img src={careInsights} alt="careInsights" className={"careInsights_img my_animation " + loaded} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="careInsight_hero_description">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="description_para">
                                <img src={educationRedBg} alt="educationRedBg" />
                                <p>Curated from TCARE experts. Check out our resources for your caregiving needs as well as posts
                                    directly from the TCARE team.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

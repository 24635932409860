// config.js

export const defaultBindings = {
    local: {
        hostUrl: 'http://localhost:3000',
        b2cPolicies: {
            names: {
                signIn: 'B2C_1_consumer_sign_in',
                signUp: 'B2C_1A_SIGNUP'
            },
            authorities: {
                signIn: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1_consumer_sign_in',
                },
                signUp: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1A_SIGNUP',
                }
            },
            authorityDomain: 'login-dev.mytcare.ai',
            scopes: [
                'openid'
            ]
        },
        apiUrls: {
            profile: 'https://profile-api-dev.mytcare.ai',
        },
        uiUrls: {
            connect: 'https://connect-dev.mytcare.ai'
        },
        accountUrls: {
            signIn: 'https://login-dev.mytcare.ai/signin-localhost',
            signUp: 'https://login-dev.mytcare.ai/signup-localhost',
            signOut: 'https://login-dev.mytcare.ai/signout-localhost',
            signInRedirect: 'http://localhost:3000/post-signin',
            signUpRedirect: 'http://localhost:3000/post-signup',
            signOutRedirect: 'http://localhost:3000/post-signout',
        },
        appId: '9b6df13a-5691-45f3-bf06-8302fb6e7fc1',
        tenantId: 'd1a8b045-38f4-4333-8ffb-c082abb910fc',
    },
    dev: {
        hostUrl: 'https://dev.mytcare.ai',
        b2cPolicies: {
            names: {
                signIn: 'B2C_1_consumer_sign_in',
                signUp: 'B2C_1A_SIGNUP'
            },
            authorities: {
                signIn: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1_consumer_sign_in',
                },
                signUp: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1A_SIGNUP',
                }
            },
            authorityDomain: 'login-dev.mytcare.ai',
            scopes: [
                'openid'
            ]
        },
        apiUrls: {
            profile: 'https://profile-api-dev.mytcare.ai',
        },
        uiUrls: {
            connect: 'https://connect-dev.mytcare.ai'
        },
        accountUrls: {
            signIn: 'https://login-dev.mytcare.ai/signin',
            signUp: 'https://login-dev.mytcare.ai/signup',
            signOut: 'https://login-dev.mytcare.ai/signout',
            signInRedirect: 'https://login-dev.mytcare.ai/post-signin',
            signUpRedirect: 'https://login-dev.mytcare.ai/post-signup',
            signOutRedirect: 'https://login-dev.mytcare.ai/post-signout',
        },
        appId: '9b6df13a-5691-45f3-bf06-8302fb6e7fc1',
        tenantId: 'd1a8b045-38f4-4333-8ffb-c082abb910fc',
    },
    qa: {
        hostUrl: 'https://qa.mytcare.ai',
        b2cPolicies: {
            names: {
                signIn: 'B2C_1_consumer_sign_in',
                signUp: 'B2C_1A_SIGNUP'
            },
            authorities: {
                signIn: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1_consumer_sign_in',
                },
                signUp: {
                    authority: 'https://login-dev.mytcare.ai/devmytcareai.onmicrosoft.com/B2C_1A_SIGNUP',
                }
            },
            authorityDomain: 'login-dev.mytcare.ai',
            scopes: [
                'openid'
            ]
        },
        apiUrls: {
            profile: 'https://profile-api-qa.mytcare.ai',
        },
        uiUrls: {
            connect: 'https://connect-dev.mytcare.ai'
        },
        accountUrls: {
            signIn: 'https://login-dev.mytcare.ai/signin',
            signUp: 'https://login-dev.mytcare.ai/signup',
            signOut: 'https://login-dev.mytcare.ai/signout',
            signInRedirect: 'https://login-dev.mytcare.ai/post-signin',
            signUpRedirect: 'https://login-dev.mytcare.ai/post-signup',
            signOutRedirect: 'https://login-dev.mytcare.ai/post-signout',
        },
        appId: '9b6df13a-5691-45f3-bf06-8302fb6e7fc1',
        tenantId: 'd1a8b045-38f4-4333-8ffb-c082abb910fc',
    },
    uat: {
        hostUrl: 'https://uat.mytcare.ai',
        b2cPolicies: {
            names: {
                signIn: 'B2C_1_consumer_sign_in',
                signUp: 'B2C_1A_SIGNUP'
            },
            authorities: {
                signIn: {
                    authority: 'https://login-test.mytcare.ai/testmytcareai.onmicrosoft.com/B2C_1_consumer_sign_in',
                },
                signUp: {
                    authority: 'https://login-test.mytcare.ai/testmytcareai.onmicrosoft.com/B2C_1A_SIGNUP',
                }
            },
            authorityDomain: 'login-test.mytcare.ai',
            scopes: [
                'openid'
            ]
        },
        apiUrls: {
            profile: 'https://profile-api-uat.mytcare.ai',
        },
        uiUrls: {
            connect: 'https://connect-test.mytcare.ai'
        },
        accountUrls: {
            signIn: 'https://login-test.mytcare.ai/signin',
            signUp: 'https://login-test.mytcare.ai/signup',
            signOut: 'https://login-test.mytcare.ai/signout',
            signInRedirect: 'https://login-test.mytcare.ai/post-signin',
            signUpRedirect: 'https://login-test.mytcare.ai/post-signup',
            signOutRedirect: 'https://login-test.mytcare.ai/post-signout',
        },
        appId: '4ffb8a3e-c510-4532-94e6-61176da29b68',
        tenantId: '548dc257-89b5-401c-bd66-fea4a8338c47',
    },
    prod: {
        hostUrl: 'https://www.mytcare.ai',
        b2cPolicies: {
            names: {
                signIn: 'B2C_1_consumer_sign_in',
                signUp: 'B2C_1A_SIGNUP'
            },
            authorities: {
                signIn: {
                    authority: 'https://login.mytcare.ai/mytcareai.onmicrosoft.com/B2C_1_consumer_sign_in',
                },
                signUp: {
                    authority: 'https://login.mytcare.ai/mytcareai.onmicrosoft.com/B2C_1A_SIGNUP',
                }
            },
            authorityDomain: 'login.mytcare.ai',
            scopes: [
                'openid'
            ]
        },
        apiUrls: {
            profile: 'https://profile-api.mytcare.ai',
        },
        uiUrls: {
            connect: 'https://connect.mytcare.ai'
        },
        accountUrls: {
            signIn: 'https://login.mytcare.ai/signin',
            signUp: 'https://login.mytcare.ai/signup',
            signOut: 'https://login.mytcare.ai/signout',
            signInRedirect: 'https://login.mytcare.ai/post-signin',
            signUpRedirect: 'https://login.mytcare.ai/post-signup',
            signOutRedirect: 'https://login.mytcare.ai/post-signout',
        },
        appId: '46dd4717-caf6-441e-9248-fb849ccd892e',
        tenantId: '2f65a84b-3195-4b3d-a3b7-5457a471e05c',
    }
}

const getEnvironment = () => {
    let env = 'local'
    switch(process.env.REACT_APP_ENV) {
        case 'local':
            env = 'local'
            break
        case 'develop':
        case 'dev':
            env = 'dev'
            break
        case 'qa':
            env = 'qa'
            break
        case 'uat':
            env = 'uat'
            break
        case 'production':
        case 'prod':
            env = 'prod'
            break
        default:
            env = 'local'
    }
    return env;
}

export const env = getEnvironment();


export const getBindings = (env = '') => {
    if (env === '') {
        env = getEnvironment()
    }
    switch (env) {
        case 'local' :
            return defaultBindings.local
        case 'dev' :
            return defaultBindings.dev
        case 'qa':
            return defaultBindings.qa
        case 'uat':
            return defaultBindings.uat
        case 'prod' :
            return defaultBindings.prod
        default      :
            return defaultBindings.local
    }
}

export default getBindings;


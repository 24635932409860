import React, { useEffect, useState } from 'react'
import { connectLink } from '../Components/DynamicConnectLink'
import { health, molecular, careRed, educationRed } from '../Components/Images'
import { useGetBrandData } from '../Components/useGetBrandData';

export const ResMainSection = ({ path, brandName }) => {
    const [, , resources, , digitalCareCommunity] = useGetBrandData({ brandName });
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="resource_main_section" className={brand}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="res_link_wrapper">
                                    <div className="resource_link">
                                        <img src={health} alt="health" style={{ marginRight: '20px' }} className='screener_icon' />
                                        <a href={`${path}`}>Caregiver Screener</a>
                                    </div>
                                    {
                                        brandName === '/gtl' ?
                                            <p className="res_main_para">
                                                Complete the caregiver screener to identify your current caregiver burnout risk measures and stress burdens.
                                            </p>
                                            :
                                            <p className="res_main_para">Complete the caregiver screener to identify your current caregiver risk
                                                measures and stress
                                                burdens.</p>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="res_link_wrapper">
                                    <div className="resource_link">
                                        <img src={molecular} alt="molecular" style={{ marginRight: '-2px' }} className='connect_icon' />
                                        {/* <a href="https://connect-ui-demo.tailoredcare.org/#/landing-branded">TCARE Connect</a> */}
                                        <a href={connectLink}>TCARE Connect</a>
                                    </div>
                                    <p className="res_main_para">Search for local and national service providers and resources to support your caregiving needs and responsibilities.</p>
                                </div>
                            </div>
                        </div>

                        {
                            digitalCareCommunity &&
                            <div className="row">
                                <div className="col">
                                    <div className="res_link_wrapper">
                                        <div className="resource_link">
                                            <img src={careRed} alt="careRed" style={{ marginRight: '20px' }} className='community_icon' />
                                            <a href={digitalCareCommunity}>Digital Care Community</a>
                                        </div>
                                        <p className="res_main_para">Connect with an online community providing tools and educational
                                            resources to support you through
                                            your caregiving journey.</p>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* <div className="row">
                        <div className="col">
                            <div className="res_link_wrapper">
                                <div className="resource_link">
                                    <img src={educationRed} alt="educationRed" style={{ marginRight: '20px' }} />
                                    <a href="/#">Other Benefits Available to You</a>
                                </div>
                                <p className="res_main_para">Additional benefits through your employer may support your caregiving
                                    responsibilities and needs.
                                    To access these benefits please refer to your benefit guide or contact your HR department.
                                </p>
                            </div>
                        </div>
                    </div> */}
                        {
                            resources &&

                            <div className="row">
                                <div className="col">
                                    <div className="res_link_wrapper">
                                        <div className="resource_link">
                                            <img src={educationRed} alt="educationRed" style={{ marginRight: '20px' }} className='other_benefits_icon' />
                                            <a href={resources.headingUrl}>{resources.heading}</a>
                                        </div>
                                        {resources.subPara && <p className="res_main_para">{resources.subPara}</p>}
                                        {resources.additionalContent && <p className="res_main_para pt-0">{resources.additionalContent}</p>}
                                        {resources.gtlAdditionalContent && resources.gtlAdditionalContent}
                                        {
                                            resources.contactDetails && resources.contactDetails.map((x) => {
                                                return (
                                                    <p className='res_main_para pt-0' key={x.name}>
                                                        <span style={{ fontWeight: '500' }}>
                                                            {x.name}
                                                        </span>
                                                        <br />
                                                        <a href={`mailto:${x.email}`} className="">{x.email}</a> <br />
                                                        <a href={`tel:${x.phone}`} className="">{x.phone}</a>
                                                    </p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </section>
            </React.Fragment>
    )
}

import React from 'react'
import { cloudDownload } from '../Components/Images'
export const DownloadApp = ({ brandName }) => {
    var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
    return (
        <React.Fragment>
            <a href={`${brandName}/app`} className={`download_app ${brand}`}>
                <div className="d-flex flex-column">
                    <span className="download_span_1">DOWNLOAD THE</span>
                    <span className="download_span_2">TCARE App</span>
                </div>
                <img src={cloudDownload} alt="cloudDownload" className="cloud_download" />
            </a>
        </React.Fragment>
    )
}

import React from "react";
import Button from "react-bootstrap/Button";
import {getBrandedUrl, signIn, signOut, signUp} from "../api/auth";
import {useIsAuthenticated} from "@azure/msal-react";
import {dropDownArrow} from "./Images";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import getBindings from "../config";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const Login = () => {
    const isAuthenticated = useIsAuthenticated()

    return (
        <div>
            {
                isAuthenticated
                    ?
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                <AccountCircleIcon style={{fontSize:'30px'}}  />
                                <span className="dropdown_arrow">
                                    <img src={dropDownArrow} alt="dropDownArrow" />
                                </span>
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href={getBrandedUrl('profile')}>Profile</a></li>
                                <li onClick={() => signOut()}><a className="dropdown-item" href={`${getBindings().accountUrls.signOutRedirect}`}>Logout</a></li>
                            </ul>
                        </li>
                    :
                    <div>
                        <Button style={{backgroundColor:'#391A52', color:'#FEF9F1', borderRadius:'25px', border:'0'}} className="ml-auto signUpBtn" onClick={() => signUp()}>Sign up</Button>
                        <Button style={{backgroundColor:'#391A52', color:'#FEF9F1', borderRadius:'25px', border:'0', marginLeft:'.25em'}} className="ml-auto signInBtn" onClick={() => signIn()}>Login</Button>
                    </div>
            }
        </div>
    );
}


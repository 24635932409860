import React, { useEffect, useState } from "react";
import { CareInsightCard } from "../Components/CareInsightCard";
import {
    burdens_small,
    caringParent,
    howCaregiving_small,
    neighbour,
} from "../Components/Images";

export const MoreInsightsSection = ({ moreHeader, brandName }) => {
    const [articleCount, setArticleCount] = useState(3);
    const articles = [
        {
            id: 1,
            src: howCaregiving_small,
            alt: "howCaregiving_small",
            desc: "How Caregiving Changes Your Identity",
            url: `${brandName}/caregiverInsights/caregiving-changes-your-identity`,
        },
        {
            id: 2,
            src: burdens_small,
            alt: "burdens_small",
            desc: "Caregiver Burdens",
            url: `${brandName}/caregiverInsights/caregiver-burdens`,
        },
        {
            id: 3,
            src: neighbour,
            alt: "neighbour",
            desc: "When Neighbors and Friends Become Caregivers",
            url: "https://www.aarp.org/caregiving/basics/info-2019/family-caregiving-community-connections.html",
        },
        {
            id: 4,
            src: caringParent,
            alt: "caringParent",
            desc: "Keeping Your Marriage Strong While Caring For a Parent",
            url: "https://feeds-aarp-org.cdn.ampproject.org/c/s/feeds.aarp.org/caregiving/life-balance/info-2019/keeping-your-marriage-strong.html?_amp=true",
        }
    ];
    // console.log(articleCount);

    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? "" :
            <React.Fragment>
                <section id="careInsightCard_section" className={`${brand}${moreHeader ? ' addBg' : ''}`}>
                    <div className="container">
                        {
                            moreHeader ?
                                <div className="row">
                                    <div className="col">
                                        <h1 className="moreInsights_header">More Insights & News</h1>
                                    </div>
                                </div>
                                : ''
                        }
                        <div className="row">
                            {
                                articles.map((a, i) => {
                                    return a.id >= articleCount - 2 && a.id <= articleCount ? (
                                        <React.Fragment key={a.id}>
                                            {/* <h1 style={{ color: 'white' }}>{a.alt}</h1> */}
                                            <div className="col-md-4 mx-auto">
                                                <CareInsightCard
                                                    key={a.id}
                                                    src={a.src}
                                                    alt={a.alt}
                                                    desc={a.desc}
                                                    url={a.url}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : ''
                                })
                            }


                            {/* <div className="col-md-4 mx-auto">
                            <CareInsightCard
                                src={howCaregiving_small}
                                alt="howCaregiving_small"
                                desc="How Caregiving Changes Your Identity"
                                url="/caregiverInsights/caregiving-changes-your-identity"
                            />
                        </div>
                        <div className="col-md-4 mx-auto">
                            <CareInsightCard
                                src={burdens_small}
                                alt="burdens_small"
                                desc="Caregiver Burdens"
                                url="/caregiverInsights/caregiver-burdens"
                            />
                        </div>
                        <div className="col-md-4 mx-auto">
                            <CareInsightCard
                                src={neighbour}
                                alt="neighbour"
                                desc="When Neighbors and Friends Become Caregivers"
                                url=""
                            />
                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <div className="row">

                                    {
                                        articleCount && articleCount <= articles.length ?
                                            <div className="col-md-6 mx-auto">
                                                <a href="/#" className="articleNextBtn mx-auto" onClick={(e) => {
                                                    setArticleCount(prev => prev + 3)
                                                    e.preventDefault()
                                                }}>Next&nbsp; &gt;</a>
                                            </div>
                                            : ''
                                    }


                                    {
                                        articleCount && articleCount > 3 ?
                                            <div className="col-md-6 mx-auto">
                                                <a href="/#" className="articlePrevBtn mx-auto" onClick={(e) => {
                                                    setArticleCount(prev => prev - 3)
                                                    e.preventDefault()
                                                }}>&lt; &nbsp;Previous</a>
                                            </div>
                                            : ''
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </React.Fragment>
    );
};

import { useEffect, useState } from 'react'

export const useLoaded = () => {
    const [loaded, setLoaded] = useState("");

    // This will run one time after the component mounts
    useEffect(() => {
        const onPageLoad = () => {
            setLoaded('loaded');
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);
    return [loaded]
}

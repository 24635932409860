import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { chat } from '../Components/Images'
import { useLoaded } from '../Components/useLoaded'

export const ContactSection = ({ brandName, formSubmitted, handleSubmit, register, onSubmit }) => {
    const [loaded] = useLoaded()
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])

    return (
        loading ? '' :
            <React.Fragment>
                <section id='contact_section' className={brand}>
                    <section id='contact_header_section' className={brand}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <p className='contact_us'>Contact Us</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className='contact_hero_main'>
                                        <img src={chat} alt="chat" className={'chat my_animation ' + loaded} />

                                        {/* <h2 className='contact_heading'>Let's chat about reducing caregiver burnout.</h2> */}
                                        <h2 className='contact_heading'>Connect with a TCARE Specialist to learn more about reducing your risk for caregiver burnout</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id='contact_inner_section' className={brand}>
                        <div className='container'>
                            <div className="row">
                                <div className="col">
                                    <div className='contact_form_outer'>
                                        {
                                            formSubmitted ?
                                                <span className='w-100 alert alert-secondary p-4 p-md-5 text-center' style={{ borderRadius: '25px' }}>
                                                    <h3>Thank you, your request to connect has been received!</h3>
                                                    <p>Our TCARE Specialists look forward to supporting you as you care for your loved one, <br /> and will be reaching out shortly.</p>
                                                </span>
                                                :
                                                <form action="" className='contact_form' method='post' onSubmit={handleSubmit(onSubmit)}>
                                                    <input type="text" name="firstName" id="firstName" {...register("firstName")} className='form-control my_input input_text' placeholder='First Name' required />
                                                    <input type="text" name="lastName" id="lastName" {...register("lastName")} className='form-control my_input input_text' placeholder='Last Name' required />
                                                    <input type="email" name="email" id="email" {...register("email")} className='form-control my_input input_text' placeholder='Email' required />
                                                    <input type="tel" name="mobile" id="mobile" {...register("mobile")} className='form-control my_input input_text' placeholder='Phone' required />
                                                    <select name="role" id="role" {...register("role")} className='form-control form-select my_select'>
                                                        <option value id='initial_select'>Please Select</option>
                                                        <option value="Policyholder" className='kk'>Policyholder</option>
                                                        <option value="Spouse | Caregiver">Spouse | Caregiver</option>
                                                        <option value="Adult Child | Caregiver">Adult Child | Caregiver</option>
                                                        <option value="Other Caregiver">Other Caregiver</option>
                                                    </select>
                                                    <textarea name="message" id="message" {...register("message")} className='form-control my_input my_textarea' maxLength="5000" placeholder='Additional Information'></textarea>
                                                    <input type="submit" value="Submit" className='submit_btn' />
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </React.Fragment>
    )
}

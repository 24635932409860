import React, { useEffect, useState } from "react";
import { butterfly_small } from "../Components/Images";

export const CareIdentityMainSection = ({ brandName }) => {
    const [brand, setBrand] = useState('') // capture new brand name for changing design
    const [loading, setLoading] = useState(true) // stop showing previous brand design
    useEffect(() => {
        var brand = brandName && brandName !== "" ? brandName.replace('/', '') : ''
        setBrand(brand)
        setLoading(false)
    }, [brandName])
    return (
        loading ? '' :
            <React.Fragment>
                <section id="combined_article_main_section" className={brand}>
                    {
                        brand === 'reframefinancial' ?
                            <section id='article_hero_description' className={brand}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="description_para">
                                                <p>
                                                    There is no such thing as an “average” caregiver. Specific families, cultures, and expectations concerning duties they are supposed to perform — even the amount of pressure they feel to take on...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''
                    }
                    <section id="article_main_section" className={brand}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="articleMain_outer">
                                        <div className="articleMain_header">
                                            <div className="articleMain_left">
                                                {/* <img
                                        src={careInsightMain_large}
                                        alt="careInsightMain_large"
                                    /> */}
                                                <div className="tcare_team">
                                                    <img
                                                        src={butterfly_small}
                                                        alt="butterfly_small"
                                                        width={60}
                                                    />
                                                    <p>TCARE TEAM</p>
                                                </div>
                                            </div>

                                            <div className="articleMain_right">
                                                <h2>October 14, 2022</h2>
                                                <p className="para_1">Caregiving</p>
                                            </div>
                                        </div>
                                        <div className="articleMain_content">
                                            <p>
                                                There is no such thing as an “average” caregiver. Specific
                                                families, cultures, and expectations concerning duties they
                                                are supposed to perform — even the amount of pressure they
                                                feel to take on the caregiving role in the first place — are
                                                all variables that affect the experience of a caregiver. And
                                                because every caregiver experience is a unique situation,
                                                knowing how one caregiver experiences the role tells us very
                                                little about how other caregivers, even if they are
                                                performing similar duties, experience their own role.
                                            </p>

                                            <p>
                                                When trying to identify challenges and prevent caregiver
                                                burnout, most people tend to focus on these duties of
                                                caregivers — how many hours are spent cooking, cleaning,
                                                providing personal care, and more. However, through our 35+
                                                years of research, we have discovered that burnout is not a
                                                result of the hours put into doing laundry or picking up
                                                medication from the pharmacy. Instead, it’s a result of
                                                identity discrepancy.
                                            </p>

                                            <h2 className="sub_heading">What is Identity Discrepancy?</h2>

                                            <p>
                                                The first time a woman bathes her mother-in-law. The first
                                                time a wife has to take away her husband’s car keys. The
                                                first time a person ceases to see themselves as a daughter
                                                or wife — but instead as a caregiver.
                                            </p>

                                            <p>
                                                We call this Identity Discrepancy — a conflict between how a
                                                caregiver sees themselves in relation to their loved one and
                                                the caretaking tasks they are now required to provide.
                                                Taking on new responsibilities as a caretaker makes
                                                caregivers see themselves as more of a nurse or a home aide,
                                                rather than a loved family member. And this conflict between
                                                their identities is what can cause distress. The level of
                                                distress depends on how much a caregiver’s current identity
                                                within their relationship clashes with what the caregiver
                                                duties they are now responsible for.
                                            </p>

                                            <h2 className="sub_heading">
                                                Caregiving is a Journey of Continuous Change
                                            </h2>

                                            <p>
                                                Caregiving is a series of transitions that result from
                                                changes in the caregiving context. Over time, the needs of a
                                                family member will increase in quantity and intensity, and
                                                so naturally, the duties of a caregiver will increase as
                                                well.
                                            </p>

                                            <p>
                                                In the beginning, caregiving might look like picking up
                                                groceries for mom on the way home from work. Over time, it
                                                could evolve into the caregiver moving in with mom to give
                                                them 24/7 care. The visits become less about “catching up
                                                and spending time” and more about “cleaning, cooking, and
                                                taking care.” Through these changes, caregivers will
                                                experience different stages of caregiving.
                                            </p>

                                            <h2 className="sub_heading">Stages of Caregiving</h2>

                                            <p>
                                                The process of identity change in caregiving often includes
                                                some of the below phases:
                                            </p>

                                            <h6 className="small_heading">
                                                Caregivers begin to perform caregiving activities that have
                                                not been their responsibility in the past.
                                            </h6>

                                            <p>
                                                A son may start taking his mother shopping for groceries
                                                when it becomes difficult for her to go on her own. A wife
                                                may take on financial tasks when her husband’s dementia
                                                progresses even though, at one point, it was part of his
                                                responsibilities in the household.
                                            </p>

                                            <h6 className="small_heading">
                                                Needs of the care recipient require caregiving activities
                                                that extend far beyond the boundaries of friends and family.
                                            </h6>

                                            <p>
                                                In many cases, these activities involve helping a loved one
                                                with personal grooming. For some caregivers — adult
                                                children, for example — and for recipients, this type of
                                                care can cause varying levels of discomfort. This phase may
                                                require duties that the caregiver is not comfortable with,
                                                and can cause the caregiver to leave their caregiving role.
                                                This can cause other changes like a move to a nursing home
                                                for a family member.
                                            </p>

                                            <h6 className="small_heading">
                                                As caregiving requirements increase, caregivers shift their
                                                identity so that caregiving ends up dominating their role in
                                                the relationship.
                                            </h6>

                                            <p>
                                                Caregivers see themselves more as caregivers than a loved
                                                one who occasionally drops by. And although the option of
                                                moving the care receiver into a nursing home is considered
                                                at every stage, it is now reconsidered more frequently.
                                            </p>

                                            <h6 className="small_heading">
                                                Care recipient is moved to a different setting and the caregiver turns over the primary responsibility for care to formal care providers.
                                            </h6>

                                            <p>
                                                Informal caregiving doesn’t end here, but the new arrangement allows the caregivers to recover a part of their original identity. Typically, the responsibilities of the caregiver now include legal, financial, and future care planning of the recipient in the new living environment.
                                            </p>

                                            <p>
                                                Not every caregiver goes through every phase, and transitions aren’t always necessarily in one direction. The only uniform part of the process is that caregivers experience distress when their identity doesn’t match up with the activities they are doing — and this prompts a movement between phases. Their distress is relieved by the transition, as their identities once again begin to match up with their caregiving activities.
                                            </p>

                                            <h1 className="large_heading">
                                                How TCARE Helps
                                            </h1>

                                            <p>
                                                TCARE is grounded on focusing on identity discrepancy as the primary driver of the emotional drain on a caregiver. By addressing these transitions in a caregiver’s journey, and tailoring interventions specific to their needs, we have found great success in reducing caregiver burnout. Currently, 84% of caregivers report lower levels of stress and depression in as few as six months after a TCARE intervention, and nursing home placement is delayed by 21 months.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </React.Fragment>
    );
};
